/*
 * Copyright 2018-2022 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { customMuiTableTheme } from 'app/modules/incidents/components/utils';
import { merge } from 'lodash';

export const defaultCursorTheme = merge({}, customMuiTableTheme, {
  overrides: {
    MUIDataTableBodyRow: {
      root: {
        cursor: 'default !important',
        '&:hover': {
          backgroundColor: 'inherit !important'
        }
      }
    }
  }
});

export const reportsOptions = {
  responsive: 'stacked',
  selectableRows: 'none',
  pagination: true,
  rowsPerPage: 10,
  rowsPerPageOptions: [5, 10, 20, 50, 100],
  search: true,
  filter: false,
  download: false,
  print: false,
  viewColumns: true,
  sortOrder: {
    name: 'column_name',
    direction: 'desc'
  }
};

export const switchReportColumn = [
  { name: 'deviceConnected', label: 'Device Connected', options: { sortDirection: 'desc' } },
  {
    name: 'firmwareVersion',
    label: 'Firmware Version',
    options: { display: false }
  },
  { name: 'ipAddress', label: 'IP Address' },
  { name: 'lastRefreshtime', label: 'Last Refresh Time' },
  { name: 'macAddress', label: 'MAC Address' },
  { name: 'model', label: 'Model' },
  { name: 'port', label: 'Port' },
  { name: 'portDescription', label: 'Port Description' },
  { name: 'portStatus', label: 'Port Status' },
  { name: 'realm', label: 'Realm', options: { display: false } },
  { name: 'serialNumber', label: 'Serial Number', options: { display: false } },
  { name: 'speed', label: 'Speed', options: { display: false } },
  { name: 'switchname', label: 'Switch Name' }
];

export const inventoryReportColumn = [
  { name: 'Property', label: 'Property' },
  { name: 'Sub Account', label: 'Sub Account' },
  { name: 'Vertical', label: 'Vertical' },
  { name: 'vmEdge', label: 'VM Edge', options: { display: false } },
  { name: 'Server0', label: 'Server 0', options: { display: false } },
  { name: 'Server MAC Serial0', label: 'Server MAC Serial 0' },
  { name: 'Server1', label: 'Server 1', options: { display: false } },
  {
    name: 'Server MAC Serial1',
    label: 'Server MAC Serial 1',
    options: { display: false }
  },
  {
    name: 'Wired/Wireless',
    label: 'Wired/Wireless',
    options: { display: false }
  },
  {
    name: 'AP FW Version',
    label: 'AP FW Version',
    options: { display: false }
  },
  { name: 'AP Model0', label: 'AP Model 0' },
  { name: 'AP MAC Serial0', label: 'AP MAC Serial 0' },
  { name: 'AP QTY0', label: 'AP QTY 0', options: { display: false } },
  { name: 'AP Model1', label: 'AP Model 1' },
  { name: 'AP MAC Serial1', label: 'AP MAC Serial 1' },
  { name: 'AP QTY1', label: 'AP QTY 1', options: { display: false } },
  { name: 'Agg Switch0', label: 'Agg Switch 0', options: { display: false } },
  {
    name: 'Agg MAC Serial0',
    label: 'Agg MAC Serial 0',
    options: { display: false }
  },
  { name: 'Agg QTY0', label: 'Agg QTY 0', options: { display: false } },
  { name: 'Agg Switch1', label: 'Agg Switch 1', options: { display: false } },
  {
    name: 'Agg MAC Serial1',
    label: 'Agg MAC Serial 1',
    options: { display: false }
  },
  { name: 'Agg QTY1', label: 'Agg QTY 1', options: { display: false } },
  { name: 'POE Switch0', label: 'POE Switch 0', options: { display: false } },
  {
    name: 'PoE MAC Serial0',
    label: 'PoE MAC Serial 0',
    options: { display: false }
  },
  { name: 'PoE QTY0', label: 'PoE QTY 0', options: { display: false } },
  { name: 'Notes', label: 'Notes', options: { display: false } },
  { name: 'vTenant', label: 'vTenant', options: { display: false } },
  { name: 'Wired Only', label: 'Wired Only', options: { display: false } },
  { name: 'Zipcode', label: 'Zipcode', options: { display: false } },
  { name: 'Address', label: 'Address', options: { display: false } }
];

export const generateClientColumn = [
  { name: 'realmid', label: 'Realm ID' },
  { name: 'client', label: 'Device' },
  { name: 'name', label: 'Name' },
  { name: 'manufacturer', label: 'Manufacturer' },
  { name: 'switch', label: 'Switch' },
  { name: 'port', label: 'Port' },
  { name: 'firstAccess', label: 'First Access' },
  { name: 'lastAccess', label: 'Last Access' },
  { name: 'validFrom', label: 'Valid From' },
  { name: 'validTo', label: 'Valid To' }
];

export const generateIncidentReportColumn = [
  { name: 'casenumber', label: 'Case Number', options: { display: false } },
  { name: 'activitytype', label: 'Activity Type' },
  { name: 'caseowner', label: 'Case Owner', options: { display: false } },
  { name: 'prioritycode', label: 'Priority Code', options: { display: false } },
  { name: 'datetimeopened', label: 'Datetime Opened', options: { display: false } },
  { name: 'status', label: 'Status' },
  { name: 'subject', label: 'Subject' },
  { name: 'statusreason', label: 'Status Reason' },
  { name: 'description', label: 'Description', options: { display: false } },
  {
    name: 'datatimelastmodified',
    label: 'Datetime Last Modified',
    options: { display: false }
  },
  { name: 'actualend', label: 'Actual End', options: { display: false } },
  { name: 'accountid', label: 'Account ID', options: { display: false } },
  { name: 'agedays', label: 'Age Days', options: { display: false } },
  { name: 'age', label: 'Age' },
  {
    name: 'caseopenedbyname',
    label: 'Case Opened By Name',
    options: { display: false }
  },
  {
    name: 'caseopenedbyteam',
    label: 'Case Opened By Team',
    options: { display: false }
  },
  { name: 'caseorigin', label: 'Case Origin' },
  { name: 'casestage', label: 'Case Stage', options: { display: false } },
  { name: 'casetitle', label: 'Case Title', options: { display: false } },
  { name: 'casetype', label: 'Case Type' },
  { name: 'childcases', label: 'Child Cases', options: { display: false } },
  { name: 'contact', label: 'Contact' },
  { name: 'contactid', label: 'Contact ID', options: { display: false } },
  { name: 'contactname', label: 'Contact Name', options: { display: false } },
  {
    name: 'cursumnextaction',
    label: 'Cur Sum Next Action',
    options: { display: false }
  },
  { name: 'customerid', label: 'Customer ID', options: { display: false } },
  { name: 'devicesimpacted', label: 'Devices Impacted', options: { display: false } },
  {
    name: 'enduserdevicemac',
    label: 'End User Device MAC',
    options: { display: false }
  },
  {
    name: 'enduserdevicemanf',
    label: 'End User Device Manufacturer',
    options: { display: false }
  },
  {
    name: 'enduserdevicemodel',
    label: 'End User Device Model',
    options: { display: false }
  },
  {
    name: 'enduserdeviceos',
    label: 'End User Device OS',
    options: { display: false }
  },
  { name: 'engagetype', label: 'Engage Type', options: { display: false } },
  { name: 'escalatedon', label: 'Escalated On', options: { display: false } },
  {
    name: 'escalationlevel',
    label: 'Escalation Level',
    options: { display: false }
  },
  {
    name: 'externalticket',
    label: 'External Ticket',
    options: { display: false }
  },
  {
    name: 'firstresponseby',
    label: 'First Response By',
    options: { display: false }
  },
  {
    name: 'firstresponsebykpi',
    label: 'First Response By KPI',
    options: { display: false }
  },
  {
    name: 'firstresponsebysla',
    label: 'First Response By SLA',
    options: { display: false }
  },
  { name: 'followupby', label: 'Follow Up By', options: { display: false } },
  {
    name: 'grandparentaccount',
    label: 'Grandparent Account',
    options: { display: false }
  },
  { name: 'impact', label: 'Impact', options: { display: false } },
  { name: 'isescalated', label: 'Is Escalated', options: { display: false } },
  { name: 'issuereported', label: 'Issue Reported', options: { display: false } },
  { name: 'issueresolved', label: 'Issue Resolved', options: { display: false } },
  { name: 'issuestart', label: 'Issue Start', options: { display: false } },
  { name: 'jiraid', label: 'JIRA ID', options: { display: false } },
  {
    name: 'lastmodifiedbyname',
    label: 'Last Modified By Name',
    options: { display: false }
  },
  { name: 'ownerteam', label: 'Owner Team', options: { display: false } },
  { name: 'parentaccount', label: 'Parent Account', options: { display: false } },
  {
    name: 'parentcaseidval',
    label: 'Parent Case ID Value',
    options: { display: false }
  },
  { name: 'property', label: 'Property', options: { display: false } },
  {
    name: 'recoverydatetime',
    label: 'Recovery Datetime',
    options: { display: false }
  },
  {
    name: 'resolutiondate',
    label: 'Resolution Date',
    options: { display: false }
  },
  {
    name: 'resolutiontype',
    label: 'Resolution Type',
    options: { display: false }
  },
  { name: 'resolveby', label: 'Resolve By', options: { display: false } },
  {
    name: 'resolvebykpi',
    label: 'Resolve By KPI',
    options: { display: false }
  },
  {
    name: 'resolvebysla',
    label: 'Resolve By SLA',
    options: { display: false }
  },
  { name: 'rootcause', label: 'Root Cause', options: { display: false } },
  { name: 'servicetype', label: 'Service Type', options: { display: false } },
  { name: 'severity', label: 'Severity', options: { display: false } },
  { name: 'sla', label: 'SLA', options: { display: false } },
  { name: 'stagestatus', label: 'Stage Status', options: { display: false } },
  {
    name: 'truckrollneeded',
    label: 'Truck Roll Needed',
    options: { display: false }
  },
  { name: 'incidentid', label: 'Incident ID', options: { display: false } },
  { name: 'name', label: 'Name', options: { display: false } },
  { name: 'address1', label: 'Address 1', options: { display: false } },
  { name: 'address2', label: 'Address 2', options: { display: false } },
  { name: 'address3', label: 'Address 3', options: { display: false } },
  { name: 'city', label: 'City', options: { display: false } },
  { name: 'email', label: 'Email' },
  { name: 'firstname', label: 'First Name', options: { display: false } },
  { name: 'lastname', label: 'Last Name', options: { display: false } },
  { name: 'mobilephone', label: 'Mobile Phone', options: { display: false } },
  { name: 'state', label: 'State', options: { display: false } },
  { name: 'telephone', label: 'Telephone',  options: { display: false } },
  { name: 'zipcode', label: 'Zip Code' }
];
