/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { BlockContainer, Tabs, TitleBar } from 'app/components/elements';
import Filters from 'app/components/filters';
import { TypeaheadField } from 'app/components/layout';
import { isSpecificNodeType } from 'app/components/layout/components/sidebar/utils';
import { styleLibrary } from 'app/constants';
import { createShowPanelSelector } from 'app/redux/app';
import {
  fetchAleSessionsSummary,
  fetchCBRsInventorySummary,
  fetchPropertyClientStatus,
  fetchPropertyClients,
  fetchPropertyMqttClientDetails,
  fetchPropertyUniqueClientsTrend,
  fetchPropertyUniqueUsersTrend,
  fetchPropertyUsers
} from 'app/redux/clients';
import {
  dateRangeFilterLabelSelector,
  ssidWLANListSelector
} from 'app/redux/filters';
import {
  hierarchyNodeSelected,
  hierarchySelector,
  selectedPathSelector
} from 'app/redux/hierarchy';
import { fetchZabbixElementReports } from 'app/redux/inventory';
import { fetchAccClientsTrend, fetchPropertyAPs } from 'app/redux/network';
import { fetchRogueDetails, fetchRogueTrend } from 'app/redux/rogue';
import { getVerticalParent } from 'app/utils';
import { findIndex, get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import styled from 'styled-components';
import {
  PropertyAPs,
  PropertyAleSessions,
  PropertyCBRSInventory,
  PropertyClients,
  PropertyDataTrend,
  PropertyRogue,
  PropertyUsers
} from './components';
import GetListOfMQTTClientTable from './components/property-mqtt-clients/property-mqtt-clients-table';
import { noDataRateChartVerticals, showUserTabForVerticals } from './constants';
import { getWlanList } from 'app/components/filters/components/ssid-wlan-filter';

const TabContainer = styled.div.attrs({
  className: 'px-2 pb-4'
})`
  & .tab-wrapper {
    border: 1px solid ${styleLibrary.containerBorderColor};
    border-top: none;
    padding-top: 0.1rem;
  }
`;

const defaultTabs = [
  { id: 'data-trend-tab', label: 'Data Trend' },
  { id: 'ap-details-tab', label: 'AP Details' },
  { id: 'clients-tab', label: 'Clients' },
  { id: 'users-tab', label: 'Users' },
  { id: 'rogue-tab', label: 'Rogue' },
  { id: 'mqtt-client-tab', label: 'MQTT Clients' },
  { id: 'cbrs-inventory-tab', label: 'CBRS' },
  { id: 'ale-sessions-tab', label: 'Session' }
];

export class Properties extends Component {
  constructor(props) {
    super(props);

    const { selectedPath = {} } = props;
    const { id = '', typeahead = '' } = selectedPath;
    const isProperty = isSpecificNodeType(selectedPath, 'zonename');

    this.state = {
      selectedProperty: isProperty ? id : '',
      typeaheadValue: isProperty ? [typeahead] : [],
      tabs: defaultTabs,
      selectedTab: defaultTabs[0].id
    };

    this.propertySearch = React.createRef();
    this.typeaheadField = React.createRef();
  }

  componentDidMount = () => {
    this.updateTabs();
    this.dispatchAPIRequests();
  };

  componentDidUpdate = (prevProps, prevState) => {
    const {
      dateRangeFilterLabel: prevRange,
      selectedPath: prevPath = {}
    } = prevProps;
    const {
      dateRangeFilterLabel: currentRange,
      selectedPath: currentPath = {},
      hierarchyNodeSelected
    } = this.props;
    const { selectedProperty: prevProperty, selectedTab: prevTab } = prevState;
    const {
      selectedProperty: currentProperty,
      selectedTab: currentTab
    } = this.state;

    // Handle the scenario of a property being selected from the hierarchy tree
    if (
      !isEmpty(currentPath) &&
      get(currentPath, 'id', '') !== get(prevPath, 'id', '') &&
      isSpecificNodeType(currentPath, 'zonename')
    ) {
      this.setState({
        selectedProperty: currentPath.id,
        typeaheadValue: [currentPath.typeahead]
      });
      return;
    }

    // Handle the scenario of a property being selected in the typeahead
    if (currentProperty !== '' && currentProperty !== prevProperty) {
      hierarchyNodeSelected(currentProperty);
      this.updateTabs();
      this.dispatchAPIRequests();
      return;
    }

    // Handle the scenario of a change in the date range
    if (prevRange !== currentRange) {
      this.dispatchAPIRequests(true);
      return;
    }

    // Handle the scenario of a change in the selected tab
    if (currentTab !== prevTab) {
      this.dispatchAPIRequests();
      return;
    }
  };

  // Check if we want to show the data trend chart or not
  displayDataTrendTab = () => {
    const { hierarchy, showSwitchCharts } = this.props;
    const { selectedProperty } = this.state;

    if (showSwitchCharts && selectedProperty !== '') {
      const nodeVertical = getVerticalParent(
        hierarchy,
        hierarchy[selectedProperty]
      );
      return !noDataRateChartVerticals.includes(nodeVertical.name);
    }
    return showSwitchCharts;
  };

  // Check if we want to show the Clients tab or not
  displayClientsTab = () => {
    const {
      showClients,
      showConnectedClientsTrend,
      showUniqueClientsTrend,
      showPropertyClientsTable
    } = this.props;
    return (
      showClients &&
      (showConnectedClientsTrend ||
        showUniqueClientsTrend ||
        showPropertyClientsTable)
    );
  };

  // Check if we want to show the Users tab or not
  displayUsersTab = () => {
    const {
      hierarchy,
      showUsers,
      showUniqueUsersTrend,
      showPropertyUsersTable
    } = this.props;
    const { selectedProperty } = this.state;

    if (showUsers && selectedProperty !== '') {
      const nodeVertical = getVerticalParent(
        hierarchy,
        hierarchy[selectedProperty]
      );
      if (showUserTabForVerticals.includes(nodeVertical.name)) {
        if (showUniqueUsersTrend || showPropertyUsersTable) {
          return true;
        }
      }
    }
    return false;
  };

  // Check if we want to show the Rogue tab or not
  displayRogueTab = () => {
    const {
      showRogue,
      showRogueMACCountTrend,
      showRogueDetailsTable
    } = this.props;
    return showRogue && (showRogueMACCountTrend || showRogueDetailsTable);
  };

  // Check if we want to show the MQTT tab or not
  displayMqttClientsTab = () => {
    const { showMqttClient, showMqttClientDetailsTable } = this.props;
    return showMqttClient && showMqttClientDetailsTable;
  };

  // Check if we want to show the CBRS tab or not
  displayCbrInventoryTab = () => {
    const { showCBRsInventory, showCBRsInventorySummary } = this.props;
    return showCBRsInventory && showCBRsInventorySummary;
  };

  // Check if we want to show the Session tab or not
  displayAleSessionTab = () => {
    const { showAleSessions, showAleSessionsTable } = this.props;
    return showAleSessions && showAleSessionsTable;
  };

  updateTabs = () => {
    let { selectedTab } = this.state;

    // Get the display tabs by filtering the default tabs
    const tabs = defaultTabs.filter(({ id }) => {
      switch (id) {
        case 'data-trend-tab':
          return this.displayDataTrendTab();
        case 'clients-tab':
          return this.displayClientsTab();
        case 'users-tab':
          return this.displayUsersTab();
        case 'rogue-tab':
          return this.displayRogueTab();
        case 'mqtt-client-tab':
          return this.displayMqttClientsTab();
        case 'cbrs-inventory-tab':
          return this.displayCbrInventoryTab();
        case 'ale-sessions-tab':
          return this.displayAleSessionTab();
        default:
          return true;
      }
    });

    // Switch to a new selected tab if required
    let tabChanged = false;
    if (findIndex(tabs, ['id', selectedTab]) < 0) {
      selectedTab = tabs[0].id;
      tabChanged = true;
    }

    // Finally, set the display tabs and the new selected tab and force API dispatch
    this.setState({ tabs, selectedTab }, () => {
      if (tabChanged) {
        this.dispatchAPIRequests();
      }
    });
  };

  // eslint-disable-next-line complexity
  dispatchAPIRequests = (dateRangeChanged = false) => {
    const {
      hierarchy,
      fetchPropertyAPs,
      fetchZabbixElementReports,
      fetchPropertyClientStatus,
      fetchPropertyUniqueClientsTrend,
      fetchPropertyUsers,
      fetchPropertyClients,
      showConnectedClientsTrend,
      showUniqueClientsTrend,
      showPropertyClientsTable,
      fetchPropertyUniqueUsersTrend,
      showPropertyUsersTable,
      showUniqueUsersTrend,
      fetchRogueTrend,
      fetchRogueDetails,
      fetchPropertyMqttClientDetails,
      showMqttClientDetailsTable,
      showRogueMACCountTrend,
      showRogueDetailsTable,
      fetchAccClientsTrend,
      showPropertyCards,
      showCBRsInventorySummary,
      fetchCBRsInventorySummary,
      showAleSessionsTable,
      fetchAleSessionsSummary,
      ssidWLANList
    } = this.props;
    const { selectedProperty, selectedTab } = this.state;
    const property = hierarchy[selectedProperty];
    const wlanValue = getWlanList(ssidWLANList);

    if (selectedProperty !== '') {
      switch (selectedTab) {
        case 'data-trend-tab':
          if (!dateRangeChanged && this.displayDataTrendTab()) {
            fetchZabbixElementReports({ property });
          }
          break;

        case 'ap-details-tab':
          fetchPropertyAPs({ property, skipCache: true });
          break;

        case 'clients-tab':
          if (this.displayClientsTab()) {
            if (showConnectedClientsTrend) {
              fetchPropertyClientStatus({ property });
            }
            if (showUniqueClientsTrend) {
              fetchPropertyUniqueClientsTrend({ property });
            }
            if (showPropertyClientsTable) {
              fetchPropertyClients({ property });
            }
          }
          break;

        case 'users-tab':
          if (this.displayUsersTab()) {
            if (showPropertyCards) {
              fetchAccClientsTrend({ zone: property.name });
            }
            if (showUniqueUsersTrend) {
              fetchPropertyUniqueUsersTrend({ property, wlanName: wlanValue });
            }
            if (showPropertyUsersTable) {
              fetchPropertyUsers({ property });
            }
          }
          break;

        case 'rogue-tab':
          if (this.displayRogueTab()) {
            if (showRogueMACCountTrend) {
              fetchRogueTrend({ property });
            }
            if (showRogueDetailsTable) {
              fetchRogueDetails();
            }
          }
          break;

        case 'mqtt-client-tab':
          if (this.displayMqttClientsTab()) {
            if (showMqttClientDetailsTable) {
              fetchPropertyMqttClientDetails();
            }
          }
          break;

        case 'cbrs-inventory-tab':
          if (this.displayCbrInventoryTab()) {
            if (showCBRsInventorySummary) {
              fetchCBRsInventorySummary();
            }
          }
          break;

        case 'ale-sessions-tab':
          if (this.displayAleSessionTab()) {
            if (showAleSessionsTable) {
              fetchAleSessionsSummary();
            }
          }
          break;

        default:
          break;
      }
    }
  };

  // If the typeahead is changed, update the relevant state. To ensure the
  // right UX, we want to automatically blur from the typeahead field so that
  // any subsequent user action will show all available properties again.
  handleTypeaheadChanged = value => {
    if (value) {
      const { id, typeahead } = value;
      this.setState({ selectedProperty: id, typeaheadValue: [typeahead] });

      const { current: typeaheadField } = this.typeaheadField;
      if (this.typeaheadField) {
        typeaheadField.getInstance().blur();
      }
    }
  };

  // If we focus on the field and it already has a value, we want to reset
  // the field, clear the value and ensure that the full list of properties
  // is displayed again to the user.
  handleTypeaheadFocus = () => {
    const { current: typeaheadField } = this.typeaheadField;
    const { hierarchy } = this.props;
    const { typeaheadValue } = this.state;

    if (typeaheadField) {
      if (isEmpty(hierarchy)) {
        typeaheadField.getInstance().blur();
      } else if (typeaheadValue.length > 0) {
        this.setState({ typeaheadValue: [] }, () =>
          typeaheadField.getInstance().clear()
        );
      }
    }
  };

  renderSelectedTabContainer = () => {
    const { hierarchy } = this.props;
    const { selectedProperty, selectedTab } = this.state;
    const hierarchyProperty = hierarchy[selectedProperty];
    const nodeVertical = getVerticalParent(hierarchy, hierarchyProperty);

    switch (selectedTab) {
      case 'data-trend-tab':
        return <PropertyDataTrend property={hierarchyProperty} />;
      case 'ap-details-tab':
        return (
          <PropertyAPs property={hierarchyProperty} vertical={nodeVertical} />
        );
      case 'clients-tab':
        return <PropertyClients />;
      case 'users-tab':
        return <PropertyUsers />;
      case 'rogue-tab':
        return <PropertyRogue />;
      case 'mqtt-client-tab':
        return <GetListOfMQTTClientTable />;
      case 'cbrs-inventory-tab':
        return <PropertyCBRSInventory />;
      case 'ale-sessions-tab':
        return <PropertyAleSessions />;
      default:
        return <div></div>;
    }
  };

  render = () => {
    const { hierarchy } = this.props;
    const { selectedProperty, typeaheadValue, tabs, selectedTab } = this.state;

    const displayProperty =
      selectedProperty !== ''
        ? hierarchy[selectedProperty].name
        : 'No property selected';
    const typeaheadPlaceholder = isEmpty(hierarchy)
      ? 'Please wait for the properties to load...'
      : 'Click / Enter to search for a specific property by name...';

    return (
      <div className="row no-gutters">
        <BlockContainer classes={['col']} minHeight="0">
          <TitleBar
            id="properties-title"
            dark
            leftChildren={displayProperty}
            padUnderTitle={false}
            rightChildren={
              <div className="d-flex justify-content-spread align-items-center">
                <Filters />
              </div>
            }
          />
          <div className="p-3">
            <TypeaheadField
              filter="zonename"
              handleChange={this.handleTypeaheadChanged}
              handleTypeaheadFocus={this.handleTypeaheadFocus}
              id="property-typeahead"
              labelKey="typeahead"
              onRef={ref => (this.typeaheadField = ref)}
              placeholder={typeaheadPlaceholder}
              ref={this.propertySearch}
              resetOnChange={false}
              selected={typeaheadValue}
              typeahead={hierarchy}
            />
          </div>
          {selectedProperty !== '' && (
            <div className="row">
              <div className="col">
                <Tabs
                  tabs={tabs}
                  selected={selectedTab}
                  onTabChanged={id => this.setState({ selectedTab: id })}
                />
                <TabContainer>
                  <div className="tab-wrapper">
                    {this.renderSelectedTabContainer()}
                  </div>
                </TabContainer>
              </div>
            </div>
          )}
        </BlockContainer>
      </div>
    );
  };
}

Properties.propTypes = {
  dateRangeFilterLabel: PropTypes.string,
  fetchZabbixElementReports: PropTypes.func,
  fetchPropertyAPs: PropTypes.func,
  hierarchy: PropTypes.object,
  hierarchyNodeSelected: PropTypes.func,
  selectedPath: PropTypes.object,
  showSwitchCharts: PropTypes.bool,
  fetchPropertyClientStatus: PropTypes.func,
  fetchPropertyUsers: PropTypes.func,
  fetchPropertyClients: PropTypes.func,
  fetchPropertyUniqueClientsTrend: PropTypes.func,
  showConnectedClientsTrend: PropTypes.bool,
  showUniqueClientsTrend: PropTypes.bool,
  showPropertyClientsTable: PropTypes.bool,
  fetchPropertyUniqueUsersTrend: PropTypes.func,
  showUniqueUsersTrend: PropTypes.bool,
  fetchRogueTrend: PropTypes.func,
  fetchRogueDetails: PropTypes.func,
  showRogue: PropTypes.bool,
  fetchPropertyMqttClientDetails: PropTypes.func,
  showRogueMACCountTrend: PropTypes.bool,
  showRogueDetailsTable: PropTypes.bool,
  showClients: PropTypes.bool,
  showUsers: PropTypes.bool,
  showPropertyUsersTable: PropTypes.bool,
  fetchAccClientsTrend: PropTypes.func,
  showPropertyCards: PropTypes.bool,
  showMqttClient: PropTypes.bool,
  showMqttClientDetailsTable: PropTypes.bool,
  fetchCBRsInventorySummary: PropTypes.func,
  fetchAleSessionsSummary: PropTypes.func,
  showCBRsInventory: PropTypes.bool,
  showAleSessions: PropTypes.bool,
  showCBRsInventorySummary: PropTypes.bool,
  showAleSessionsTable: PropTypes.bool,
  ssidWLANList: PropTypes.array
};

const mapStateToProps = createSelector(
  createShowPanelSelector('PoESwitch'),
  createShowPanelSelector('PropertyClients'),
  createShowPanelSelector('PropertyConnectedClientsTrend'),
  createShowPanelSelector('PropertyUniqueClientsTrend'),
  createShowPanelSelector('PropertyClientsTable'),
  createShowPanelSelector('PropertyUsers'),
  createShowPanelSelector('PropertyUniqueUsersTrend'),
  createShowPanelSelector('PropertyUsersTable'),
  createShowPanelSelector('PropertyRogue'),
  createShowPanelSelector('PropertyRogueMACCountTrend'),
  createShowPanelSelector('PropertyRogueDetailsTable'),
  createShowPanelSelector('PropertyCards'),
  createShowPanelSelector('PropertyMqttClient'),
  createShowPanelSelector('PropertyMqttClientDetailsTable'),
  createShowPanelSelector('PropertyCBRsInventory'),
  createShowPanelSelector('PropertyCBRsInventorySummary'),
  createShowPanelSelector('PropertyAleSessionsDetails'),
  createShowPanelSelector('PropertyAleSessionTable'),
  dateRangeFilterLabelSelector,
  hierarchySelector,
  selectedPathSelector,
  ssidWLANListSelector,
  (
    showSwitchCharts,
    showClients,
    showConnectedClientsTrend,
    showUniqueClientsTrend,
    showPropertyClientsTable,
    showUsers,
    showUniqueUsersTrend,
    showPropertyUsersTable,
    showRogue,
    showRogueMACCountTrend,
    showRogueDetailsTable,
    showPropertyCards,
    showMqttClient,
    showMqttClientDetailsTable,
    showCBRsInventory,
    showCBRsInventorySummary,
    showAleSessions,
    showAleSessionsTable,
    dateRangeFilterLabel,
    hierarchy,
    selectedPath,
    ssidWLANList
  ) => ({
    showSwitchCharts,
    showClients,
    showConnectedClientsTrend,
    showUniqueClientsTrend,
    showPropertyClientsTable,
    showUsers,
    showUniqueUsersTrend,
    showPropertyUsersTable,
    showRogue,
    showRogueMACCountTrend,
    showRogueDetailsTable,
    showPropertyCards,
    showMqttClient,
    showMqttClientDetailsTable,
    showCBRsInventory,
    showCBRsInventorySummary,
    showAleSessions,
    showAleSessionsTable,
    dateRangeFilterLabel,
    hierarchy,
    selectedPath,
    ssidWLANList
  })
);

const mapDispatchToProps = {
  fetchZabbixElementReports,
  fetchPropertyAPs,
  hierarchyNodeSelected,
  fetchPropertyClientStatus,
  fetchPropertyUsers,
  fetchPropertyClients,
  fetchPropertyUniqueClientsTrend,
  fetchPropertyUniqueUsersTrend,
  fetchRogueTrend,
  fetchRogueDetails,
  fetchPropertyMqttClientDetails,
  fetchAccClientsTrend,
  fetchCBRsInventorySummary,
  fetchAleSessionsSummary
};

export default connect(mapStateToProps, mapDispatchToProps)(Properties);
