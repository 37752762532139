/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { defaultDateFormat } from 'app/constants';
import { ajaxPost } from 'app/redux/util-sagas';
import { createErrorAction } from 'app/redux/utils';
import { get } from 'lodash';
import moment from 'moment';
import { call, getContext, put, takeLatest } from 'redux-saga/effects';
import {
  fetchInventoryReportComplete,
  fetchInventoryReportError,
  reportError,
  updateInventoryReportFetchState
} from './';

const url = '/api/report/getInventoryReport';

export function* fetchInventoryReportSaga({ payload }) {
  yield put(
    updateInventoryReportFetchState({ inProgress: true, failed: false })
  );

  const {
    property = {},
    vertical,
    subcustomer,
    ...otherParams
  } = yield payload;
  const complexApiBody = yield JSON.parse(
    get(property, 'complexApiBody', '{}')
  );

  const finalBody = yield {
    ...complexApiBody,
    vertical,
    subcustomer,
    timeUnit: 'month',
    timeUnitFrom: moment()
      .startOf('month')
      .format(defaultDateFormat),
    timeUnitTo: moment().format(defaultDateFormat),
    ...otherParams
  };

  const finalOptions = yield {
    headers: { Accept: 'text/csv' },
    responseType: 'blob',
    onTimeout: () => put(updateInventoryReportFetchState({ failed: true }))
  };

  const saveFileAs = yield getContext('saveFileAs');

  const filename = yield get(property, 'name', 'Property').toUpperCase() +
    '_' +
    vertical.toUpperCase() +
    '_' +
    subcustomer.toUpperCase() +
    '_InventoryReport_' +
    moment().format('YYYYMMDD_HHmm') +
    '.csv';

  try {
    const { data } = yield ajaxPost(url, finalBody, finalOptions);
    if (!(data instanceof Blob)) {
      throw yield new Error('No data');
    }
    if (yield get(payload, 'download', false)) {
      yield call(saveFileAs, data, filename);
    }
    yield put(fetchInventoryReportComplete(data));
    yield put(updateInventoryReportFetchState({ failed: false }));
  } catch (err) {
    yield put(createErrorAction(fetchInventoryReportError, err));
    yield put(updateInventoryReportFetchState({ failed: true }));
    yield put(
      reportError({
        action: 'generate',
        type: 'inventory-report',
        report: { ...finalBody },
        error: err
      })
    );
  } finally {
    yield put(updateInventoryReportFetchState({ inProgress: false }));
  }
}

export default function createInventoryReportSagas(types) {
  return [takeLatest([types.fetchInventoryReport], fetchInventoryReportSaga)];
}
