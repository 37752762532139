/*
 * Copyright 2018-2022 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { NoDataToShow } from 'app/components/elements';
import {
  LoadingIconPlaceholder,
  ExportCsvIcon,
  FaIcon,
  StyledExportButton
} from 'app/components/icons';
import {
  defaultCursorTheme,
  generateClientColumn,
  reportsOptions
} from '../utils';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { StatefulTable } from 'app/components/tables';
import {
  clientReportFetchStateSelector,
  clientReportDataSelector,
  fetchClientReport
} from 'app/redux/reports';
import Papa from 'papaparse';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { includes, isArray, isEmpty, toLower } from 'lodash';
import { FailedFetchStateHandler } from 'app/components/utility';
import { Parser } from 'json2csv';

class ClientReportTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchValue: '',
      parsedData: [],
      isParsing: false
    };
  }

  componentDidMount = () => {
    const {
      clientReportFetchState: { inProgress, failed },
      clientReportData
    } = this.props;

    if (!inProgress && !failed && clientReportData instanceof Blob) {
      this.convertCSVToJSON(clientReportData);
    }
  };

  componentDidUpdate = prevProps => {
    const {
      clientReportFetchState: { inProgress: prevInProgress }
    } = prevProps;
    const {
      clientReportFetchState: { inProgress, failed },
      clientReportData
    } = this.props;

    if (
      prevInProgress &&
      !inProgress &&
      !failed &&
      clientReportData instanceof Blob
    ) {
      this.convertCSVToJSON(clientReportData);
    }
  };

  convertCSVToJSON = data => {
    this.setState({ isParsing: true });
    const file = new File([data], 'deviceReport.csv');
    Papa.parse(file, {
      header: true,
      worker: true,
      skipEmptyLines: true,
      dynamicTyping: true,
      complete: results =>
        this.setState({ isParsing: false, parsedData: results.data }),
      error: () => this.setState({ isParsing: false, parsedData: [] })
    });
  };

  filterBySearch = data => {
    const { searchValue } = this.state;

    if (isEmpty(searchValue)) {
      return data;
    }
    // Remove colons and hyphens from the search value
    const processedSearchValue = searchValue.replace(/[:-]/g, '');
    const lowerSearchValue = toLower(processedSearchValue);

    return data.filter(row =>
      Object.values(row).some(value => {
        const lowerValue = toLower(String(value).replace(/[:-]/g, ''));
        return includes(toLower(lowerValue), lowerSearchValue);
      })
    );
  };

  handleCSVExport = () => {
    const { parsedData } = this.state;
    const customHeader = generateClientColumn.map(c => ({
      label: c.label,
      value: row => row[c.name]
    }));
    const parser = new Parser({ fields: customHeader });
    const csv = parser.parse(parsedData);
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'DeviceReport.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  renderCustomToolbar = () => (
    <StyledExportButton title="Generate CSV" onClick={this.handleCSVExport}>
      <ExportCsvIcon />
    </StyledExportButton>
  );

  renderCustomSearch = () => {
    const { searchValue } = this.state;

    return (
      <div className="d-flex justify-content-between align-items-center m-2">
        <div className="d-flex align-items-center">
          <FaIcon icon="search" classes={['mr-2']} />
          <label className="sr-only">Search...</label>
          <input
            className="p-1"
            id="reports-device-table-search"
            onChange={e => this.setState({ searchValue: e.target.value })}
            placeholder="Search..."
            style={{ width: 250 }}
            type="text"
            value={searchValue}
          />
        </div>
      </div>
    );
  };

  render = () => {
    const {
      clientReportData,
      fetchClientReport,
      clientReportFetchState: { inProgress }
    } = this.props;
    const { parsedData, isParsing } = this.state;

    const hasData =
      clientReportData instanceof Blob &&
      isArray(parsedData) &&
      !isEmpty(parsedData);
    const noDataToShow = !inProgress && !isParsing && !hasData;

    const clientTableOptions = {
      ...reportsOptions,
      customToolbar: this.renderCustomToolbar,
      customSearchRender: this.renderCustomSearch
    };

    return (
      <Fragment>
        <FailedFetchStateHandler
          fetchState={{ pending: inProgress, complete: !inProgress }}
          retry={fetchClientReport}
        >
          {inProgress || isParsing ? (
            <LoadingIconPlaceholder position="relative" />
          ) : noDataToShow ? (
            <NoDataToShow
              message="No Device Data Available!"
              style={{ background: 'none', position: 'relative' }}
            />
          ) : (
            hasData && (
              <Fragment>
                <MuiThemeProvider theme={createMuiTheme(defaultCursorTheme)}>
                  <StatefulTable
                    tableKey="clint-report"
                    data={this.filterBySearch(parsedData)}
                    columns={generateClientColumn}
                    options={clientTableOptions}
                  />
                </MuiThemeProvider>
              </Fragment>
            )
          )}
        </FailedFetchStateHandler>
      </Fragment>
    );
  };
}

ClientReportTable.propTypes = {
  clientReportFetchState: PropTypes.object,
  clientReportData: PropTypes.object,
  fetchClientReport: PropTypes.func
};

const mapStateToProps = createSelector(
  clientReportFetchStateSelector,
  clientReportDataSelector,
  (clientReportFetchState, clientReportData) => ({
    clientReportFetchState,
    clientReportData
  })
);

const mapDispatchToProps = {
  fetchClientReport
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientReportTable);
 