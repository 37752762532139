/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { defaultDateFormat } from 'app/constants';
import { ajaxGet } from 'app/redux/util-sagas';
import { createErrorAction } from 'app/redux/utils';
import { get } from 'lodash';
import moment from 'moment';
import { call, getContext, put, takeLatest } from 'redux-saga/effects';
import {
  fetchIncidentReportComplete,
  fetchIncidentReportError,
  reportError,
  updateIncidentReportFetchState
} from '.';

const url = '/api/report/getIncidentReport';

export function* fetchIncidentReportSaga({ payload }) {
  yield put(
    updateIncidentReportFetchState({ inProgress: true, failed: false })
  );

  const {
    property = {},
    vertical,
    subcustomer,
    status,
    dateRange,
    ...otherParams
  } = yield payload;

  const complexApiBody = yield JSON.parse(
    get(property, 'complexApiBody' || '{}')
  );

  const finalBody = yield {
    ...complexApiBody,
    vertical,
    subcustomer,
    status,
    timeUnit: 'month',
    timeUnitFrom: moment()
      .startOf('months')
      .format(defaultDateFormat),
    timeUnitTo: moment().format(defaultDateFormat),
    ...otherParams
  };

  if (dateRange) {
    finalBody.timeUnit = dateRange.unit;
    finalBody.timeUnitFrom = moment(dateRange.start).format(defaultDateFormat);
    finalBody.timeUnitTo = moment(dateRange.end).format(defaultDateFormat);
  }

  const params = {
    vertical,
    subcustomer,
    status,
    timeUnit: finalBody.timeUnit,
    timeUnitFrom: finalBody.timeUnitFrom,
    timeUnitTo: finalBody.timeUnitTo
  };

  const paramNames = Object.entries(params)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');

  const queryString = paramNames ? `?${paramNames}` : '';
  const finalUrl = `${url}${queryString}`;

  const saveFileAs = yield getContext('saveFileAs');
  const filename =
    (property.name || 'Property').toUpperCase() +
    '_' +
    vertical.toUpperCase() +
    '_' +
    subcustomer.toUpperCase() +
    '_IncidentReport_' +
    moment().format('YYYYMMDD_HHmm') +
    '.csv';

  const ajaxOptions = yield {
    headers: { Accept: 'text/csv' },
    responseType: 'blob',
    onTimeout: () => put(updateIncidentReportFetchState({ failed: true }))
  };

  try {
    const { data } = yield ajaxGet(finalUrl, finalBody, ajaxOptions);
    if (!(data instanceof Blob)) {
      throw yield new Error('No data');
    }
    if (yield get(payload, 'download', false)) {
      yield call(saveFileAs, data, filename);
    }
    yield put(fetchIncidentReportComplete(data));
    yield put(updateIncidentReportFetchState({ failed: false }));
  } catch (err) {
    yield put(createErrorAction(fetchIncidentReportError, err));
    yield put(updateIncidentReportFetchState({ failed: true }));
    yield put(
      reportError({
        action: 'generate',
        type: 'incident-report',
        report: { ...finalBody },
        error: err
      })
    );
  } finally {
    yield put(updateIncidentReportFetchState({ inProgress: false }));
  }
}

export default function createIncidentReportSagas(types) {
  return [takeLatest([types.fetchIncidentReport], fetchIncidentReportSaga)];
}
