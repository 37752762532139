/*
 * Copyright 2018-2022 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { FaIcon } from 'app/components/icons';
import { ReportFilterWrapper } from 'app/modules/reports/utils';
import { getSubCustomers } from 'app/utils';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import ClientReportTable from './client-report-table';

export class GenerateConsolidatedClientReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vertical: 'MDU',
      subcustomer: 'all',
      showTable: false
    };
  }

  componentDidMount = () => {
    const { isClientReportGenerated, updateTableReportStatus } = this.props;
    
    if (isClientReportGenerated) {
      this.setState({ showTable: true });
    } else {
      updateTableReportStatus(false);
    }
  };

  generateReport = () => {
    const { handleGenerateReport, updateTableReportStatus } = this.props;
    const { subcustomer } = this.state;

    handleGenerateReport({
      vertical: 'MDU',
      subcustomer
    });
    this.setState({ showTable: true }, () => {
      updateTableReportStatus(true);
    });
  };

  getClientReportDescription = () => {
    const { rootNode } = this.props;
    const { vertical, subcustomer } = this.state;
    const { name, type } = rootNode;
    const subcustomerDescription =
      subcustomer === 'all'
        ? 'all subcustomers'
        : `subcustomer '${subcustomer}'`;
    const verticalDescription =
      vertical === 'all' ? 'all verticals' : `vertical '${vertical}'`;
    return (
      <Fragment>
        <p>
          A new device report will be created for the{' '}
          <b>
            {type} {name}
          </b>
          , covering <b>{subcustomerDescription}</b> and{' '}
          <b>{verticalDescription}</b>.
        </p>
      </Fragment>
    );
  };

  handleFieldChange = field => e => {
    const fieldValue = e.target.value;
    this.setState(
      prevState => ({ [field]: fieldValue, showTable: prevState.showTable }),
      () => {
        this.props.updateTableReportStatus(false);
      }
    );
  };

  render = () => {
    const {
      clientReportFetchState: { inProgress },
      selectedPath,
      hierarchy,
      rootNode,
      isClientReportGenerated
    } = this.props;
    const { vertical, subcustomer, showTable } = this.state;

    const ddSubCustomers = getSubCustomers(hierarchy, rootNode);
    const buttonDisabled = inProgress || isClientReportGenerated;

    return (
      <Fragment>
        <>
          {!isEmpty(selectedPath) && (
            <Fragment>
              <div className="d-flex justify-content-around align-items-center my-3 report-properties">
                <ReportFilterWrapper>
                  <label
                    className="col-form-label-sm pr-2"
                    htmlFor="subcustomer-filter"
                    style={{ whiteSpace: 'pre' }}
                  >
                    Sub-customer:
                  </label>
                  <select
                    className="form-control"
                    id="subcustomer-filter"
                    onChange={this.handleFieldChange('subcustomer')}
                    value={subcustomer}
                  >
                    {ddSubCustomers.map(({ value, text }) => (
                      <option key={`subcustomer-${value}`} value={value}>
                        {text}
                      </option>
                    ))}
                  </select>
                </ReportFilterWrapper>
                <ReportFilterWrapper>
                  <label
                    className="col-form-label-sm pr-2"
                    htmlFor="vertical-filter"
                  >
                    Vertical:
                  </label>
                  <select
                    className="form-control"
                    id="vertical-filter"
                    onChange={this.handleFieldChange('vertical')}
                    value={vertical}
                  >
                    <option value="MDU">MDU - Multi-Dwelling Unit</option>
                  </select>
                </ReportFilterWrapper>
              </div>
              <div className="text-center">
                {this.getClientReportDescription()}
                <p>
                  <button
                    className="btn btn-primary"
                    disabled={buttonDisabled}
                    onClick={this.generateReport}
                  >
                    {inProgress ? (
                      <span>
                        <FaIcon icon="circle-notch" spin classes={['mr-2']} />
                        Generating...
                      </span>
                    ) : (
                      'Generate Device Report'
                    )}
                  </button>
                </p>
              </div>
            </Fragment>
          )}
        </>
        {buttonDisabled || showTable ? <ClientReportTable /> : <div />}
      </Fragment>
    );
  };
}

GenerateConsolidatedClientReport.propTypes = {
  isClientReportGenerated: PropTypes.bool,
  clientReportFetchState: PropTypes.object,
  hierarchy: PropTypes.object,
  selectedPath: PropTypes.object,
  vertSSIDFilter: PropTypes.object,
  rootNode: PropTypes.object,
  handleGenerateReport: PropTypes.func,
  updateTableReportStatus: PropTypes.func,
  fetchClientReport: PropTypes.func
};

export default GenerateConsolidatedClientReport;
