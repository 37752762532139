/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { filterParamsSelector } from 'app/redux/filters';
import { hierarchySelector, selectedPathSelector } from 'app/redux/hierarchy';
import { ajaxGet, debounceSaga } from 'app/redux/util-sagas';
import {
  completeFetchState,
  createErrorAction,
  failedFetchState,
  pendingFetchState,
  timeoutNotifyFetchState
} from 'app/redux/utils';
import { getPropertyParent } from 'app/utils';
import { get, merge } from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';
// import {
//   genericAjaxPostSaga
// } from '../util-sagas/generic-ajax-sagas';
import {
  fetchPropertyAPsComplete,
  fetchPropertyAPsError,
  rawPropertyAPsSelector,
  updatePropertyAPsFetchState
} from './index';

// const propertyAPRequestUrls = [
//   '/api/network/getSPUsageByAP',
//   '/api/network/getSPClientByAP',
//   '/api/device/getInventoryApDetails'
// ];

// export function* fetchPropertyAPsSaga({ payload }) {
//   const complexApiBody = get(payload, 'property.complexApiBody', {});
//   const skipCache = get(payload, 'skipCache', false);

//   const sagaOptions = {
//     completeAction: fetchPropertyAPsComplete,
//     errorAction: fetchPropertyAPsError,
//     currentDataSelector: rawPropertyAPsSelector,
//     updateFetchState: updatePropertyAPsFetchState
//   };

//   const requestOptions = yield {
//     skipCache,
//     headers: skipCache ? { 'hot-refresh': true } : {},
//     customBody: JSON.stringify(
//       merge({}, JSON.parse(complexApiBody), skipCache ? { refresh: true } : {})
//     )
//   };

//   yield call(
//     genericAjaxPostSaga,
//     propertyAPRequestUrls,
//     sagaOptions,
//     requestOptions
//   );
// }

export function* fetchPropertyAPsSaga({ payload }) {
  yield put(updatePropertyAPsFetchState(pendingFetchState));

  const currentDataSet = yield select(rawPropertyAPsSelector);
 
  yield call(debounceSaga, currentDataSet);

  const selectedPath = yield select(selectedPathSelector);
  const hierarchy = yield select(hierarchySelector);

  const selectedProperty = yield getPropertyParent(hierarchy, selectedPath);
  const selectedPropertyName = yield get(selectedProperty, 'name', '');
  const filters = yield select(filterParamsSelector);
  const wlanName = yield get(payload, 'wlanName', 'all');

  const params = yield merge({}, filters, payload, {
    zone: get(payload, 'property.name', selectedPropertyName),
    wlanName
  });

  const paramNames = [
    'timeUnit',
    'timeUnitFrom',
    'timeUnitTo',
    'vertical',
    'zone',
    'wlanName'
  ];
  const url = '/api/fastapi/getPropertyAPDetails';
  try {
    const onTimeout = () =>
      put(updatePropertyAPsFetchState(timeoutNotifyFetchState));

    const queryStringParams = yield paramNames
      .map(p => `${p}=${params[p]}`)
      .join('&');

    const { data } = yield ajaxGet(`${url}?${queryStringParams}`, {
      onTimeout
    });

    yield put(fetchPropertyAPsComplete(data));
    yield put(updatePropertyAPsFetchState(completeFetchState));
  } catch (e) {
    yield put(createErrorAction(fetchPropertyAPsError, e));
    yield put(updatePropertyAPsFetchState(failedFetchState));
  }
}

export default function createFetchPropertyStatsSagas(types) {
  return [takeLatest(types.fetchPropertyAPs, fetchPropertyAPsSaga)];
}
