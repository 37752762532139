/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { merge } from 'lodash';
import { styleLibrary } from './style-library';

export { styleLibrary };

export const appBuild = process.env.REACT_APP_BUILD_VERSION;

export const apiDateFormat = 'YYYY-MM-DD HH:mm:ss zz';
export const defaultDateFormat = 'YYYY-MM-DD';
export const defaultDisplayDateFormat = 'MM/DD/YYYY';
export const reportDateFormat = `${defaultDateFormat} HH:mm:ss`;
export const defaultDateTimeFormat = `${defaultDisplayDateFormat} h:mma`;
export const tooltipDateDisplayFormat = 'ddd, Do MMM h:mma';

export const bandwidthUnits = ['MB', 'GB', 'TB', 'PB'];
export const dataRateUnits = ['Kbps', 'Mbps', 'Gbps', 'Tbps'];

export const networkIntelligence = 'Network Intelligence';
export const niVersion = `${networkIntelligence} v${appBuild}`;
export const copyright = `© CommScope, Inc 2019 - ${new Date().getFullYear()} : All Rights Reserved`;
export const niCopyrightMessage = `${niVersion} | ${copyright}`;

export const commonPlotProps = {
  dot: false,
  type: 'monotone',
  strokeWidth: 1.5,
  fillOpacity: 0.5
};

export const verticals = {
  all: { label: 'All', desc: 'All', displayAPs: false },
  BaaH: {
    label: 'BaaH',
    desc: 'BaaH - Business as a Hotspot',
    displayAPs: true
  },
  ENT: { label: 'ENT', desc: 'ENT - Enterprise', displayAPs: false },
  HaaH: { label: 'HaaH', desc: 'HaaH - Home as a Hotspot', displayAPs: true },
  HOS: { label: 'HOS', desc: 'HOS - Hospitality', displayAPs: false },
  MDU: { label: 'MDU', desc: 'MDU - Multi-Dwelling Unit', displayAPs: false },
  PWF: { label: 'PWF', desc: 'PWF - Public Wi-Fi', displayAPs: true },
  SMB: { label: 'SMB', desc: 'SMB - Small/Medium Business', displayAPs: false },
  LPV: { label: 'VEN', desc: 'VEN - Large Public Venue', displayAPs: false },
  SMP: { label: 'SMP', desc: 'SMP - Simple SMB/ENT', displayAPs: false },
  CPX: { label: 'CPX', desc: 'CPX - Complex SMB/ENT', displayAPs: false },
  GOV: { label: 'GOV', desc: 'GOV - Government', displayAPs: false },
  EDU: { label: 'EDU', desc: 'EDU - Education', displayAPs: false },
  MXU: { label: 'MXU', desc: 'MXU - Mixed-Use', displayAPs: false },
  MFW: { label: 'MFW', desc: 'MFW - Managed Firewall', displayAPs: false },
};

export const MuiTableTheme = {
  props: {
    MuiButtonBase: {
      disableRipple: true
    }
  },
  shadows: Array(25).fill('none'),
  transitions: {
    create: () => 'none'
  },
  typography: {
    fontFamily: '"Open Sans", sans-serif',
    fontSize: styleLibrary.fontSizes.body + 1,
    useNextVariants: true
  },
  overrides: {
    MUIDataTable: {
      responsiveScroll: {
        maxHeight: '100%'
      }
    },
    MUIDataTableToolbar: {
      root: {
        display: 'none'
      }
    },
    MUIDataTableHeadCell: {
      sortAction: {
        display: 'flex',
        alignItems: 'center'
      }
    },
    MUIDataTableBodyRow: {
      root: {
        cursor: 'pointer',
        '&.row-selected': {
          backgroundColor: 'rgba(255, 165, 0, 0.3) !important'
        },
        '&.row-unselected': {
          opacity: 0.4
        },
        '&:hover': {
          backgroundColor: 'rgba(0, 173, 239, 0.1) !important'
        },
        '&:nth-child(odd)': {
          backgroundColor: '#f2f2f2'
        }
      }
    },
    MuiTableRow: {
      head: {
        height: 40
      }
    },
    MuiTableCell: {
      head: {
        border: `solid ${styleLibrary.brand.highlight}`,
        borderWidth: '2px 0',
        color: styleLibrary.brand.highlight,
        fontWeight: 'bold'
      },
      root: {
        padding: '0 15px'
      }
    },
    MUIDataTableToolbarSelect: {
      root: {
        display: 'none'
      }
    },
    MUIDataTableSelectCell: {
      checked: {
        '& path': {
          fill: styleLibrary.brand.highlight
        }
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: styleLibrary.fontSizes.body
      }
    },
    MuiPopover: {
      paper: {
        width: 100
      }
    }
  }
};

export const MuiTableOptions = {
  download: false,
  filter: false,
  fixedHeaderOptions: { xAxis: false, yAxis: false },
  print: false,
  responsive: 'scrollFullHeight',
  search: false,
  selectableRows: 'none',
  viewColumns: false
};

export const pdfPageSettings = {
  spacing: 5,
  width: 216,
  height: 279
};

export const chartModeSuffix = 'ChartMode';

export const allSelection = [{ value: 'all', text: 'All' }];

export const customMuiTableThemeWithDefualtCursor = merge({}, MuiTableTheme, {
  overrides: {
    MUIDataTableBodyRow: {
      root: {
        cursor: 'default !important',
        '&:hover': {
          backgroundColor: 'inherit !important'
        }
      }
    }
  }
});

export const typeDropdown = [
  { value: 'all', label: 'All' },
  { value: 'wired', label: 'Wired' },
  { value: 'wireless', label: 'Wireless' },
  { value: 'SS_wired', label: 'SS Wired' },
  { value: 'SS_wireless', label: 'SS Wireless' },
  { value: 'unknown', label: 'Unknown' }
];

export const mduTypeDropdown = [
  { value: 'all', label: 'All' },
  { value: 'SS', label: 'SS' },
  { value: 'EW', label: 'EW' },
  { value: 'VT', label: 'VT' },
  { value: 'MDU', label: 'MDU' }
];

export const lastAccessDaysDropdown = [
  { value: '30', label: '30 Days' },
  { value: '60', label: '60 Days' },
  { value: '90', label: '90 Days' }
];

export const userGroupList = ['FRONTIERUSERS', 'LAUSERS'];


