/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { FaIcon } from 'app/components/icons';
import { styleLibrary } from 'app/constants';
import { prefixNavigationPath } from 'app/utils';
import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from 'styled-components';
import {
  customDateRangeNames,
  dateRangeNames,
  getDateRange,
  selectedDateFormat
} from '../constants';
import CalendarSelection from './calendar-selection';
import MonthsSelection from './months-selection';

const dropDownPosition = `
  position: absolute;
  /* Follows Bootstrap z-index values for drop downs */
  z-index: 1000;
  right: 0;
  top: 33px;
`;

const SelectionContainer = styled.div.attrs(() => ({
  className: 'd-flex pl-2'
}))`
  ${dropDownPosition}
  border: 1px solid ${styleLibrary.containerBorderColor};
  background-color: ${styleLibrary.containerBg};
  color: ${styleLibrary.darkText};
  font-size: ${styleLibrary.fontSizes.dropDownMenu}px;
  
  .ie & {
    width: ${props => (props.mode === 'calendar' ? 1030 : 467)}px;
  }
`;

const DateRangeUl = styled.ul.attrs(() => ({
  className: 'm-0'
}))`
  background-color: ${styleLibrary.containerBg};
  margin: 0;
  padding: 0;
  min-width: 140px;
  text-align: left;
  color: ${styleLibrary.darkText};

  & li {
    padding: 8px 12px;
    font-size: 12px;
  }

  & li.active {
    background-color: ${styleLibrary.filterOpenBg};
    color: ${styleLibrary.darkText};
  }

  & li.calendar-active {
    background-color: ${styleLibrary.primaryButtonBg};
    color: ${styleLibrary.lightText};
  }
`;

const CalendarSelectionUl = styled(DateRangeUl)`
  border-left: 1px solid ${styleLibrary.containerBorderColor};
  margin-bottom: 0;
`;

const MinimalDropDownUl = styled(DateRangeUl)`
  ${dropDownPosition}
  border: 1px solid ${styleLibrary.containerBorderColor};
`;

const createCustomRangeLabel = ({ start, end }) => {
  const selectedStartDate = moment(start).format(selectedDateFormat);
  const selectedEndDate = moment(end).format(selectedDateFormat);

  return `${selectedStartDate} - ${selectedEndDate}`;
};

const defaultState = {
  calendarOpen: false,
  monthsOpen: false
};

class DateRangeFilter extends Component {
  constructor(props) {
    super(props);

    this.state = { ...defaultState, selectedLabel: '' };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isDropDownOpen && !this.props.isDropDownOpen) {
      this.setState(defaultState);
    }
  }

  getStartEndTimes(range = []) {
    if (range.length === 0) {
      const { dateRangeFilter } = this.props;
      return { start: dateRangeFilter.start, end: dateRangeFilter.end };
    }
    const [start, end] = range;
    return { start, end };
  }

  handleDropDownMenuClick = e => {
    const { dateRange: label } = e.target.dataset;
    if (!label) {
      return null;
    }

    const isCustomDaySelection = label === customDateRangeNames.CUSTOM_DAYS;
    const isCustomMonthSelection = label === customDateRangeNames.CUSTOM_MONTHS;

    this.props.updateIsOpen(isCustomDaySelection || isCustomMonthSelection);
    this.setState({
      calendarOpen: isCustomDaySelection,
      monthsOpen: isCustomMonthSelection,
      selectedLabel: label
    });

    if (isCustomDaySelection || isCustomMonthSelection) {
      return null;
    }

    const { start, end } = this.getStartEndTimes(getDateRange(label));

    return this.props.updateDateRangeFilter({
      label,
      start,
      end
    });
  };

  handleDateSelection = ({ start, end, selectionAborted }) => {
    const { updateIsOpen, updateDateRangeFilter } = this.props;
    const { selectedLabel: label } = this.state;

    if (selectionAborted) {
      updateIsOpen(false);
      return this.setState({ ...defaultState, selectedLabel: '' });
    }
    const startOfDay = moment(start)
      .startOf('day')
      .toDate();
    const endOfDay = moment(end)
      .endOf('day')
      .toDate();
    const customLabel = createCustomRangeLabel({ start, end });
    const isCustomMonths = label === customDateRangeNames.CUSTOM_MONTHS;

    updateIsOpen(false);
    if (isCustomMonths) {
      const startOfMonth = moment(start)
        .startOf('month')
        .toDate();
      const endOfMonth = moment(end)
        .endOf('month')
        .toDate();
      const isLabelCustomMonths = `${moment(startOfMonth).format(
        selectedDateFormat
      )} - ${moment(endOfMonth).format(selectedDateFormat)}`;
      updateDateRangeFilter({
        label: isLabelCustomMonths,
        start: startOfMonth,
        end: endOfMonth
      });
    } else {
      updateDateRangeFilter({
        label: customLabel,
        start: startOfDay,
        end: endOfDay
      });
    }
    return this.setState(defaultState);
  };

  handleTriggerClick = () => {
    const { calendarOpen, monthsOpen, selectedLabel: label } = this.state;
    const { updateIsOpen, isDropDownOpen } = this.props;

    if (!isDropDownOpen && (calendarOpen || monthsOpen)) {
      return this.closeDropDowns();
    }

    if (!isDropDownOpen) {
      if (label === customDateRangeNames.CUSTOM_DAYS) {
        this.setState({ calendarOpen: true });
      } else if (label === customDateRangeNames.CUSTOM_MONTHS) {
        this.setState({ monthsOpen: true });
      }
    }

    return isDropDownOpen ? this.closeDropDowns() : updateIsOpen(true);
  };

  closeDropDowns = () => {
    this.props.updateIsOpen(false);
    return this.setState(defaultState);
  };

  renderDateRangeLabels() {
    const { calendarOpen, monthsOpen } = this.state;
    const {
      dateRangeFilter: { label: currentlySelectedLabel },
      excludeLast24Hours,
      excludeCustomDaysMonths
    } = this.props;

    let dateRangeLabels;
    if (excludeLast24Hours) {
      dateRangeLabels = [
        dateRangeNames.LAST_7_DAYS,
        dateRangeNames.THIS_MONTH,
        dateRangeNames.LAST_MONTH,
        dateRangeNames.LAST_90_DAYS,
        customDateRangeNames.CUSTOM_MONTHS
      ];
    }
    if (excludeCustomDaysMonths) {
      dateRangeLabels = [
        dateRangeNames.LAST_24_HOURS,
        dateRangeNames.LAST_7_DAYS,
        dateRangeNames.THIS_MONTH,
        dateRangeNames.LAST_MONTH
      ];
    } else {
      dateRangeLabels = [
        dateRangeNames.LAST_24_HOURS,
        dateRangeNames.LAST_7_DAYS,
        dateRangeNames.THIS_MONTH,
        dateRangeNames.LAST_MONTH,
        customDateRangeNames.CUSTOM_DAYS,
        customDateRangeNames.CUSTOM_MONTHS
      ];
    }

    const isAlarmsPageOrIncidentsPage = [
      prefixNavigationPath('/alarms'),
      prefixNavigationPath('/incidents')
    ].includes(window.location.pathname);

    const isInventoryPage =
      window.location.pathname === prefixNavigationPath('/inventory');

    dateRangeLabels = isAlarmsPageOrIncidentsPage
      ? [customDateRangeNames.SHOW_ALL_OPEN_ITEMS, ...dateRangeLabels]
      : isInventoryPage
        ? [dateRangeNames.LAST_24_HOURS, ...dateRangeLabels]
        : dateRangeLabels;

    return dateRangeLabels.map(label => (
      <li
        key={label}
        className={classNames(
          'dropdown-item',
          label === currentlySelectedLabel && 'active',
          label === customDateRangeNames.CUSTOM_DAYS &&
            calendarOpen &&
            'calendar-active',
          label === customDateRangeNames.CUSTOM_MONTHS &&
            monthsOpen &&
            'calendar-active'
        )}
        data-date-range={label}
      >
        {label}
      </li>
    ));
  }

  renderMinimalDropDown(isDropDownOpen) {
    return isDropDownOpen ? (
      <MinimalDropDownUl
        id="minimal-date-range-picker"
        isDropDownOpen={isDropDownOpen}
        onClick={this.handleDropDownMenuClick}
      >
        {this.renderDateRangeLabels()}
      </MinimalDropDownUl>
    ) : null;
  }

  renderSelection(mode, { start, end }) {
    return (
      <SelectionContainer mode={mode}>
        <div>
          {mode === 'calendar' ? (
            <CalendarSelection
              end={end}
              handleDateSelection={this.handleDateSelection}
              maxRange={this.props.maxRange}
              start={start}
            />
          ) : (
            <MonthsSelection
              end={end}
              handleDateSelection={this.handleDateSelection}
              start={start}
            />
          )}
        </div>
        <div className="p-1" />
        <CalendarSelectionUl onClick={this.handleDropDownMenuClick}>
          {this.renderDateRangeLabels()}
        </CalendarSelectionUl>
      </SelectionContainer>
    );
  }

  render() {
    const { calendarOpen, monthsOpen } = this.state;
    const {
      buttonStyle,
      isDropDownOpen,
      dateRangeFilter: { label, start, end }
    } = this.props;

    return (
      <div
        className={`dropdown date-range-picker ${isDropDownOpen ? 'show' : ''}`}
      >
        <div
          className="px-3 py-1 title-btn"
          data-toggle=""
          onClick={this.handleTriggerClick}
          style={{ ...buttonStyle }}
        >
          <span>{label}</span>
          <span>
            <FaIcon
              icon="caret-down"
              style={{ fontSize: styleLibrary.fontSizes.inlineIcon }}
            />
          </span>
        </div>
        {this.renderMinimalDropDown(isDropDownOpen)}
        {calendarOpen && this.renderSelection('calendar', { start, end })}
        {monthsOpen && this.renderSelection('months', { start, end })}
      </div>
    );
  }
}

DateRangeFilter.displayName = 'DateRangeFilter';
DateRangeFilter.propTypes = {
  buttonStyle: PropTypes.object,
  dateRangeFilter: PropTypes.shape({
    label: PropTypes.string,
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date)
  }).isRequired,
  isDropDownOpen: PropTypes.bool,
  maxRange: PropTypes.number,
  updateDateRangeFilter: PropTypes.func.isRequired,
  updateIsOpen: PropTypes.func.isRequired,
  excludeLast24Hours: PropTypes.bool,
  excludeCustomDaysMonths: PropTypes.bool
};

export default DateRangeFilter;
