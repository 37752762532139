/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { types as appTypes } from 'app/redux/app';
import {
  aleAccClientsTrendTransform,
  clientOsTransform,
  manufacturerTransform
} from 'app/redux/clients/transforms';
import {
  createAsyncTypes,
  createFetchStatePayloadCreator,
  createTypes,
  pendingFetchState,
  updateFetchStateReducer
} from 'app/redux/utils';
import { get, isArray, isEmpty } from 'lodash';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import {
  customNetworkTransform,
  networkDetailsTransform,
  throughputTransform,
  usageSummaryThroughputTransform
} from './details-transformers';
import createFetchApStatsSagas from './fetch-ap-stats-saga';
import createfetchAccClientsTrendSagas from './fetch-card-summary-saga';
import createFetchNetworkStatsSagas from './fetch-network-stats-saga';
import createFetchPropertyStatsSagas from './fetch-property-stats-saga';
import { propertyAPsCombiner } from './utils';
import { castToNumber, infinityProtection } from 'app/utils';

export const nameSpace = 'network';

const types = createTypes(
  [
    ...createAsyncTypes('fetchSummary'),
    'updateSummaryFetchState',
    ...createAsyncTypes('fetchDetails'),
    'updateDetailsFetchState',
    ...createAsyncTypes('fetchThroughput'),
    'updateThroughputFetchState',
    ...createAsyncTypes('fetchPropertyAPs'),
    'updatePropertyAPsFetchState',
    ...createAsyncTypes('fetchApUsageDetails'),
    'updateApUsageDetailsFetchState',
    ...createAsyncTypes('fetchAccClientsTrend'),
    'updateAccClientsTrendFetchState'
  ],
  nameSpace
);
export const sagas = [
  ...createFetchNetworkStatsSagas(types),
  ...createFetchPropertyStatsSagas(types),
  ...createFetchApStatsSagas(types),
  ...createfetchAccClientsTrendSagas(types)
];

export const fetchNetworkSummary = createAction(types.fetchSummary);
export const fetchNetworkSummaryComplete = createAction(
  types.fetchSummaryComplete
);
export const fetchNetworkSummaryError = createAction(types.fetchSummaryError);

export const updateNetworkSummaryFetchState = createAction(
  types.updateSummaryFetchState,
  createFetchStatePayloadCreator('summary')
);

export const fetchNetworkDetails = createAction(types.fetchDetails);
export const fetchNetworkDetailsComplete = createAction(
  types.fetchDetailsComplete
);
export const fetchNetworkDetailsError = createAction(types.fetchDetailsError);

export const updateNetworkDetailsFetchState = createAction(
  types.updateDetailsFetchState,
  createFetchStatePayloadCreator('details')
);

export const fetchThroughput = createAction(types.fetchThroughput);
export const fetchThroughputComplete = createAction(
  types.fetchThroughputComplete
);
export const fetchThroughputError = createAction(types.fetchThroughputError);

export const updateThroughputFetchState = createAction(
  types.updateThroughputFetchState,
  createFetchStatePayloadCreator('throughput')
);

export const fetchPropertyAPs = createAction(types.fetchPropertyAPs);
export const fetchPropertyAPsComplete = createAction(
  types.fetchPropertyAPsComplete
);
export const fetchPropertyAPsError = createAction(types.fetchPropertyAPsError);

export const updatePropertyAPsFetchState = createAction(
  types.updatePropertyAPsFetchState,
  createFetchStatePayloadCreator('propertyAPs')
);

export const fetchApUsageDetails = createAction(types.fetchApUsageDetails);
export const fetchApUsageDetailsComplete = createAction(
  types.fetchApUsageDetailsComplete
);
export const fetchApUsageDetailsError = createAction(
  types.fetchApUsageDetailsError
);
export const updateApUsageDetailsFetchState = createAction(
  types.updateApUsageDetailsFetchState,
  createFetchStatePayloadCreator('apUsage')
);
export const fetchAccClientsTrend = createAction(types.fetchAccClientsTrend);
export const fetchAccClientsTrendComplete = createAction(
  types.fetchAccClientsTrendComplete
);
export const fetchAccClientsTrendError = createAction(
  types.fetchAccClientsTrendError
);
export const updateAccClientsTrendFetchState = createAction(
  types.updateAccClientsTrendFetchState,
  createFetchStatePayloadCreator('aleAccClientsTrend')
);

const fetchStatesSelector = state => state[nameSpace].fetchStates;

export const networkDetailsFetchStateSelector = state =>
  fetchStatesSelector(state).details;
export const networkSummaryFetchStateSelector = state =>
  fetchStatesSelector(state).summary;
export const throughputFetchStateSelector = state =>
  fetchStatesSelector(state).throughput;
export const propertyAPsFetchStateSelector = state =>
  fetchStatesSelector(state).propertyAPs;
export const apUsageDetailsFetchStateSelector = state =>
  fetchStatesSelector(state).apUsage;
export const accClientsTrendFetchStateSelector = state =>
  fetchStatesSelector(state).aleAccClientsTrend;

export const accClientsTrendSelector = state =>
  state[nameSpace].aleAccClientsTrend;
export const accClientsTrendSelectorTransform = createSelector(
  accClientsTrendSelector,
  aleAccClientsTrend => aleAccClientsTrendTransform(aleAccClientsTrend)
);

export const networkSummarySelector = state => state[nameSpace].summary;
export const networkDetailsSelector = state => state[nameSpace].details;
export const networkDetailsChartDataSelector = createSelector(
  networkDetailsSelector,
  networkSummarySelector,
  (networkDetails, networkSummary = {}) =>
    networkDetailsTransform(networkDetails, networkSummary)
);

export const getUniqueClientTrendSelector = state => {
  const {
    ActiveClientCountTrend: data = [],
    ActiveClientsTrendInfo: info = {},
    ActiveCountStats: stats = {}
  } = state[nameSpace].summary;
  return { data, info, stats };
};

export const customNetworkDataSelector = createSelector(
  getUniqueClientTrendSelector,
  networkSummarySelector,
  (uniqueClient, networkSummary = {}) =>
    customNetworkTransform(uniqueClient, networkSummary)
);

export const clientCountByDevicesSelector = state =>
  state[nameSpace].summary.SPClientDetails;
export const clientOsChartDataSelector = createSelector(
  clientCountByDevicesSelector,
  (clientCount = {}) => clientOsTransform(clientCount)
);
export const manufacturerChartDataSelector = createSelector(
  clientCountByDevicesSelector,
  (clientCount = {}) => manufacturerTransform(clientCount)
);

export const serviceProviderDetailsSelector = state =>
  networkSummarySelector(state).ServiceProvider;
export const serviceProviderInfoSelector = state =>
  networkSummarySelector(state).ServiceProviderInfo;
export const serviceProviderBoundsSelector = createSelector(
  serviceProviderDetailsSelector,
  (serviceProviders = [{ latitude: 0, longitude: 0 }]) => ({
    minLat: Math.min(...serviceProviders.map(sp => sp.latitude)),
    minLng: Math.min(...serviceProviders.map(sp => sp.longitude)),
    maxLat: Math.max(...serviceProviders.map(sp => sp.latitude)),
    maxLng: Math.max(...serviceProviders.map(sp => sp.longitude))
  })
);

export const throughputSelector = state => state[nameSpace].throughput;
export const throughputChartDataSelector = createSelector(
  throughputSelector,
  (throughput = {}) => throughputTransform(throughput)
);
export const usageSummaryThroughputSelector = createSelector(
  networkDetailsSelector,
  (details = {}) => usageSummaryThroughputTransform(details)
);

// export const rawPropertyAPsSelector = state => state[nameSpace].propertyAPs;
export const rawPropertyAPsSelector = state => {
  const { apDetailsList: data = [], info = {} } = state[
    nameSpace
  ].propertyAPs;
  return { data, info };
};

export const maxPropertyAPsValuesSelector = createSelector(
  rawPropertyAPsSelector,
  ({ data }) => {
    if (!isArray(data) || isEmpty(data)) {
      return { bandwidth: 0 };
    }
    return {
      bandwidth: infinityProtection(
        Math.max(
          ...data.map(
            d => castToNumber(d.bandwidthRX) + castToNumber(d.bandwidthTX)
          )
        )
      ),
      clients: Math.max(...data.map(x => x.clientCount))
    };
  }
);

export const bandwidthByAPSelector = state =>
  rawPropertyAPsSelector(state).SPUsageByAP;
export const clientByAPSelector = state =>
  rawPropertyAPsSelector(state).SPClientByAP;
export const propertyAPDetailsSelector = state =>
  rawPropertyAPsSelector(state).apdetails;
export const propertyAPsSelector = createSelector(
  bandwidthByAPSelector,
  clientByAPSelector,
  propertyAPDetailsSelector,
  propertyAPsCombiner
);
// export const maxPropertyAPsValuesSelector = createSelector(
//   propertyAPsSelector,
//   (usage = []) => ({
//     bandwidth: Math.max(
//       ...usage.map(x => x.bandwidthTXInKB + x.bandwidthRXInKB)
//     ),
//     clients: Math.max(...usage.map(x => x.clientCount))
//   })
// );

export const createNetworkDetailsChartInfoSelector = infoType =>
  createSelector(networkDetailsSelector, data =>
    get(data, `${infoType}Info`, {})
  );

export const usageSummaryTrendsSelector = createSelector(
  networkSummarySelector,
  networkDetailsSelector,
  (networkSummary, networkDetails = {}) => {
    // const {
    //   sessionCountByAPinZone = [],
    //   throughputByAPinZone = []
    // } = networkDetails;

    if (isEmpty(networkSummary)) {
      return {};
    } else {
      networkSummary['bandwidthByAPinZone'] = get(
        networkSummary,
        'SPTrendUsage.bandwidthByAPinZone',
        []
      );
      networkSummary['bandwidthByAPinZoneInfo'] = get(
        networkSummary,
        'SPTrendUsage.info',
        {}
      );
    }

    if (isEmpty(networkDetails)) {
      return {};
    } else {
      networkSummary['sessionCountByAPinZone'] = get(
        networkDetails,
        'SPClientSessionTrend.sessionCountByAPinZone',
        []
      );
      networkSummary['sessionCountByAPinZoneInfo'] = get(
        networkDetails,
        'SPClientSessionTrend.info',
        {}
      );
      networkSummary['throughputByAPinZone'] = get(
        networkDetails,
        'SPThroughputTrend.throughputByAPinZone',
        []
      );
      networkSummary['throughputByAPinZoneInfo'] = get(
        networkDetails,
        'SPThroughputTrend.info',
        {}
      );
      networkSummary['SPSessionDuration'] = get(
        networkDetails,
        'SPDurationUsage.SPSessionDuration',
        []
      );
      networkSummary['SPSessionDurationInfo'] = get(
        networkDetails,
        'SPDurationUsage.info',
        {}
      );
    }

    const {
      bandwidthByAPinZone = [],
      sessionCountByAPinZone = [],
      throughputByAPinZone = []
    } = networkSummary;

    return {
      bandwidth: bandwidthByAPinZone.map(x => {
        return {
          label: x.timestamp,
          value: x.bandwidthRXInMB + x.bandwidthTXInMB
        };
      }),
      download: throughputByAPinZone.map(x => {
        return {
          label: x.timestamp,
          value: x.throughputRXInKbps
        };
      }),
      upload: throughputByAPinZone.map(x => {
        return {
          label: x.timestamp,
          value: x.throughputTXInKbps
        };
      }),
      ratio: sessionCountByAPinZone.map(x => {
        return {
          label: x.timestamp,
          value: [x.activeSessions, x.activeClients] || [x.cdr, x.mqtt]
        };
      })
    };
  }
);

export const apUsageDetailsSelector = state => state[nameSpace].apUsage;
export const apUsageTrendSelector = createSelector(
  apUsageDetailsSelector,
  (apUsageDetails = {}) => {
    if (isEmpty(apUsageDetails)) {
      return {};
    }

    const {
      apBandwidthUsageTrend = [],
      apBandwidthUsageTrendInfo = {},
      apThroughputTrend = [],
      apThroughputTrendInfo = {}
    } = apUsageDetails;

    return {
      bandwidth: apBandwidthUsageTrend.map(b => ({
        label: b.timestamp,
        upload: b.bandwidthTX,
        download: b.bandwidthRX,
        total: b.bandwidthRX + b.bandwidthTX
      })),

      bandwidthInfo: apBandwidthUsageTrendInfo,

      dataRate: apThroughputTrend.map(t => ({
        label: t.timestamp,
        uploadrate: t.throughputTXInKbps,
        downloadrate: t.throughputRXInKbps
      })),

      dataRateInfo: apThroughputTrendInfo
    };
  }
);

const initialState = {
  fetchStates: {
    apUsage: pendingFetchState,
    details: pendingFetchState,
    summary: pendingFetchState,
    throughput: pendingFetchState,
    propertyAPs: pendingFetchState,
    aleAccClientsTrend: pendingFetchState
  },

  apUsage: {},
  details: {},
  summary: {},
  throughput: {},
  propertyAPs: {},
  aleAccClientsTrend: {}
};

export const reducer = handleActions(
  {
    [types.fetchDetailsComplete]: (state, { payload }) => ({
      ...state,
      details: payload
    }),

    [types.fetchSummaryComplete]: (state, { payload }) => ({
      ...state,
      summary: payload
    }),

    [types.fetchThroughputComplete]: (state, { payload }) => ({
      ...state,
      throughput: payload
    }),

    [types.fetchPropertyAPsComplete]: (state, { payload }) => ({
      ...state,
      propertyAPs: payload
    }),

    [types.fetchApUsageDetails]: state => ({
      ...state,
      apUsage: {}
    }),

    [types.fetchApUsageDetailsComplete]: (state, { payload }) => ({
      ...state,
      apUsage: payload
    }),

    [types.fetchAccClientsTrendComplete]: (state, { payload }) => ({
      ...state,
      aleAccClientsTrend: payload
    }),

    [types.updateDetailsFetchState]: updateFetchStateReducer,
    [types.updateSummaryFetchState]: updateFetchStateReducer,
    [types.updateThroughputFetchState]: updateFetchStateReducer,
    [types.updatePropertyAPsFetchState]: updateFetchStateReducer,
    [types.updateApUsageDetailsFetchState]: updateFetchStateReducer,
    [types.updateAccClientsTrendFetchState]: updateFetchStateReducer,

    [appTypes.appReset]: () => initialState
  },
  initialState
);
