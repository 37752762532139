/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { NoDataToShow } from 'app/components/elements';
import { FaIcon, LoadingIconPlaceholder } from 'app/components/icons';
import { StatefulTable, TableExport } from 'app/components/tables';
import { FailedFetchStateHandler } from 'app/components/utility';
import { MuiTableOptions, MuiTableTheme } from 'app/constants';
import { createShowPanelSelector } from 'app/redux/app';
import { dateRangeFilterLabelSelector } from 'app/redux/filters';
import {
  fetchPropertyAPs,
  maxPropertyAPsValuesSelector,
  propertyAPsFetchStateSelector,
  rawPropertyAPsSelector
} from 'app/redux/network';
import { scrollToHtmlElement } from 'app/utils';
import classNames from 'classnames';
import { filter, findIndex, isEmpty, isEqual, merge } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { gatewayVerticals } from '../../constants';
import ApCharts from './components/ap-charts';
import {
  createPropertyAPDetailsExportDataDefinition,
  getTopAPsColumns,
  sortAPsTable
} from './utils';

export class PropertyAPs extends Component {
  constructor(props) {
    super(props);
    this.state = { apSearch: '', selectedAP: '' };
  }

  componentDidMount = () => this.setState({ selectedAP: '' });

  componentDidUpdate = prevProps => {
    const propertyChanged = !isEqual(prevProps.property, this.props.property);
    const dateRangeChanged = !isEqual(
      prevProps.dateRangeFilterLabel,
      this.props.dateRangeFilterLabel
    );

    if (propertyChanged || dateRangeChanged) {
      this.setState({ selectedAP: '' });
    }
  };

  includesWithOrWithoutColon = (str, search) => {
    const lowercaseStr = str.toLowerCase();
    const lowercaseSearch = search.toLowerCase();
    return (
      lowercaseStr.includes(lowercaseSearch) ||
      lowercaseStr.replace(/:/g, '').includes(lowercaseSearch)
    );
  };

  filterData = data => {
    const { apSearch } = this.state;
    return apSearch === ''
      ? data
      : filter(data, row => {
        const apHit = this.includesWithOrWithoutColon(row.APMAC, apSearch);
        const apNameHit = row.APName.toLowerCase().includes(
          apSearch.toLowerCase()
        );
        return apHit || apNameHit;
      });
  };

  handleTableRowClick = (rowData, apMACColumnIndex) => {
    const newSelectedAP = rowData[apMACColumnIndex];

    if (newSelectedAP === this.state.selectedAP) {
      this.setState({ selectedAP: '' });
    } else {
      this.setState({ selectedAP: newSelectedAP }, () => {
        const offset = document.getElementById('property-ap-details').offsetTop;
        scrollToHtmlElement('property-ap-charts', offset);
      });
    }
  };

  setRowProps = (row, apMACColumnIndex) => ({
    className:
      this.state.selectedAP !== '' &&
      classNames(
        row[apMACColumnIndex] === this.state.selectedAP
          ? 'row-selected'
          : 'row-unselected'
      )
  });

  renderActions = (showApCharts = true) => {
    const {
      propertyAPs: { data = [] }
    } = this.props;
    return (
      <div className="d-flex justify-content-between m-2">
        <div className="d-flex justify-content-start align-items-center">
          {showApCharts &&
            'Click on any row to view usage/activity details for the selected device.'}
        </div>

        <div className="d-flex justify-content-end align-items-center">
          <FaIcon icon="search" classes={['mr-2']} />
          <label className="sr-only" htmlFor="ap-search">
            Search by Location
          </label>
          <input
            className="p-1"
            id="ap-search"
            onChange={e => this.setState({ apSearch: e.target.value })}
            placeholder="Search..."
            style={{ width: 250 }}
            type="text"
            value={this.state.apSearch}
          />
          <TableExport
            hidePdfExport={true}
            exportName="Property AP Details"
            exportDataDefinitions={createPropertyAPDetailsExportDataDefinition(
              data
            )}
          />
        </div>
      </div>
    );
  };

  render = () => {
    const {
      propertyAPs: { data = [] },
      fetchPropertyAPs,
      maxUsage,
      property,
      propertyAPsFetchState,
      vertical = {},
      showUsageTrend,
      showDataRateTrend,
      showAPConnectedClientsTrend
    } = this.props;
    const { apSearch, selectedAP } = this.state;

    const { pending, complete } = propertyAPsFetchState;
    const noDataToShow = complete && isEmpty(data);

    const columns = getTopAPsColumns(maxUsage);
    const apMACColumnIndex = findIndex(columns, ['name', 'APMAC']);
    const showApCharts =
      showUsageTrend || showDataRateTrend || showAPConnectedClientsTrend;

    const apTableOptions = merge(
      {},
      MuiTableOptions,
      { customSort: sortAPsTable },
      showApCharts
        ? {
          onRowClick: rowData =>
            this.handleTableRowClick(rowData, apMACColumnIndex),
          setRowProps: row => this.setRowProps(row, apMACColumnIndex)
        }
        : {}
    );
    const panelType = gatewayVerticals.includes(vertical.name)
      ? 'Gateway'
      : 'AP';
    const noResultsMessage =
      apSearch && apSearch !== ''
        ? 'matching your search criteria'
        : 'associated with this property';

    return (
      <div id="property-ap-details">
        {pending ? (
          <LoadingIconPlaceholder position="relative" />
        ) : noDataToShow ? (
          <NoDataToShow
            message={`There are no ${panelType}s ${noResultsMessage}`}
            style={{
              background: 'none',
              position: 'relative'
            }}
          />
        ) : (
          <FailedFetchStateHandler
            fetchState={propertyAPsFetchState}
            retry={() => fetchPropertyAPs({ property, skipCache: true })}
          >
            {!pending && !isEmpty(data) && this.renderActions(showApCharts)}
            <div
              className={pending || noDataToShow ? 'fetch-state-pending' : ''}
            >
              {complete && (
                <MuiThemeProvider theme={createMuiTheme(MuiTableTheme)}>
                  <StatefulTable
                    tableKey="property-ap-details-table"
                    columns={getTopAPsColumns(maxUsage)}
                    data={this.filterData(data)}
                    options={apTableOptions}
                  />
                </MuiThemeProvider>
              )}
            </div>
          </FailedFetchStateHandler>
        )}
        {showApCharts && selectedAP !== '' && (
          <div id="property-ap-charts">
            <ApCharts ap={selectedAP} zone={property.name} />
          </div>
        )}
      </div>
    );
  };
}

PropertyAPs.propTypes = {
  dateRangeFilterLabel: PropTypes.string,
  fetchPropertyAPs: PropTypes.func,
  maxUsage: PropTypes.shape({
    bandwidth: PropTypes.number,
    clients: PropTypes.number
  }),
  property: PropTypes.object,
  propertyAPs: PropTypes.shape({
    data: PropTypes.array,
    info: PropTypes.object
  }),
  propertyAPsFetchState: PropTypes.object,
  vertical: PropTypes.object,
  showUsageTrend: PropTypes.bool,
  showDataRateTrend: PropTypes.bool,
  showAPConnectedClientsTrend: PropTypes.bool
};

const mapStateToProps = createSelector(
  createShowPanelSelector('PropertyAPUsageTrend'),
  createShowPanelSelector('PropertyAPDataRateTrend'),
  createShowPanelSelector('PropertyAPConnectedClientsTrend'),
  dateRangeFilterLabelSelector,
  maxPropertyAPsValuesSelector,
  propertyAPsFetchStateSelector,
  rawPropertyAPsSelector,
  (
    showUsageTrend,
    showDataRateTrend,
    showAPConnectedClientsTrend,
    dateRangeFilterLabel,
    maxUsage,
    propertyAPsFetchState,
    propertyAPs
  ) => ({
    showUsageTrend,
    showDataRateTrend,
    showAPConnectedClientsTrend,
    dateRangeFilterLabel,
    maxUsage,
    propertyAPsFetchState,
    propertyAPs
  })
);

export default connect(mapStateToProps, { fetchPropertyAPs })(PropertyAPs);
