/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { FaIcon } from 'app/components/icons';
import {
  allSelection,
  defaultDisplayDateFormat,
  styleLibrary
} from 'app/constants';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { ReportFilterWrapper } from 'app/modules/reports/utils';
import { getSubCustomers, getUniqueVerticals, getTimeUnit } from 'app/utils';
import { isEmpty, isEqual } from 'lodash';
import moment from 'moment';
import DateRangeFilter from 'app/components/filters/components/date-range-filter';
import IncidentReportTable from './incident-report-table';

const statusOptions = [
  { value: 'all', label: 'All' },
  { value: 'Open', label: 'Open' },
  { value: 'Resolved', label: 'Resolved' },
  { value: 'Cancelled', label: 'Cancelled' }
];

export class GenerateIncidentReport extends Component {
  constructor(props) {
    super(props);
    const { vertical } = props.vertSSIDFilter;
    this.state = {
      isDateOpen: false,
      label: 'This Month',
      start: moment()
        .date(1)
        .startOf('day')
        .toDate(),
      end: moment()
        .subtract(1, 'days')
        .endOf('day')
        .toDate(),
      unit: 'month',
      ddVerticals: allSelection,
      ddSubCustomers: allSelection,
      vertical,
      subcustomer: 'all',
      status: 'all',
      showTable: false
    };
  }

  componentDidMount = () => {
    const {
      hierarchy,
      selectedPath,
      isIncidentReportGenerated,
      updateTableReportStatus
    } = this.props;
    const ddVerticals = getUniqueVerticals(hierarchy, selectedPath);
    const ddSubCustomers = getSubCustomers(hierarchy, selectedPath);
    const { status, unit, start, end } = this.state;
    this.setState({
      ddVerticals,
      ddSubCustomers,
      vertical: ddVerticals[0].value,
      subcustomer: ddSubCustomers[0].value,
      status,
      dateRange: { unit, start, end }
    });
    if (isIncidentReportGenerated) {
      this.setState({ showTable: true });
    } else {
      updateTableReportStatus(false);
    }
  };

  componentDidUpdate = prevProps => {
    const { selectedPath, hierarchy } = this.props;
    const { selectedPath: prevselectedPath } = prevProps;

    if (!isEqual(selectedPath, prevselectedPath)) {
      const ddVerticals = getUniqueVerticals(hierarchy, selectedPath);
      const ddSubCustomers = getSubCustomers(hierarchy, selectedPath);
      const { status, unit, start, end } = this.state;
      this.setState({
        ddVerticals,
        ddSubCustomers,
        vertical: ddVerticals[0].value,
        subcustomer: ddSubCustomers[0].value,
        status,
        dateRange: { unit, start, end }
      });
    }
  };

  generateReport = () => {
    const {
      handleGenerateReport,
      selectedPath,
      updateTableReportStatus
    } = this.props;
    const { vertical, subcustomer, status, unit, start, end } = this.state;

    handleGenerateReport({
      property: selectedPath,
      vertical,
      subcustomer,
      dateRange: { unit, start, end },
      status
    });
    this.setState({ showTable: true }, () => {
      updateTableReportStatus(true);
    });
  };

  handleDateSelection = selectedDate => {
    const { label, start, end } = selectedDate;
    const startDate = moment(start);
    let endDate = moment(end);

    if (startDate.diff(endDate, 'days') === 0) {
      endDate.add(1, 'days');
    }

    const unit = getTimeUnit(label, startDate, endDate);

    this.setState(
      prevState => ({
        label,
        start: startDate.toDate(),
        end: endDate.toDate(),
        unit,
        showTable: prevState.showTable
      }),
      () => {
        this.props.updateTableReportStatus(false);
      }
    );
  };

  handleFieldChange = field => e => {
    const fieldValue = e.target.value;
    this.setState(
      prevState => ({ [field]: fieldValue, showTable: prevState.showTable }),
      () => {
        this.props.updateTableReportStatus(false);
      }
    );
  };

  toggleDate = isDateOpen => this.setState({ isDateOpen });

  getIncidentReportDescription = () => {
    const { selectedPath } = this.props;
    const { vertical, subcustomer, start, end, unit, status } = this.state;
    const { name, type } = selectedPath;
    const subcustomerDescription =
      subcustomer === 'all'
        ? 'all subcustomers'
        : `subcustomer '${subcustomer}'`;
    const verticalDescription =
      vertical === 'all' ? 'all verticals' : `vertical '${vertical}'`;

    const statusDescription =
      status === 'all' ? 'all status' : `status '${status}'`;

    const unitDescription = () => {
      switch (unit) {
        case 'month':
        case 'week':
          return 'daily';
        case '24Hours':
          return 'hourly';
        default:
          return 'monthly';
      }
    };

    return (
      <Fragment>
        <p>
          A new incident report will be created for the{' '}
          <b>
            {type} {name}
          </b>
          , covering <b>{subcustomerDescription}</b> and{' '}
          <b>{verticalDescription}</b> and{''}&nbsp;
          <b>{statusDescription}</b> and over dates{' '}
          <b>
            {moment(start).format(defaultDisplayDateFormat)} -{' '}
            {moment(end).format(defaultDisplayDateFormat)}
          </b>{' '}
          with <b>{unitDescription()}</b> data points.
        </p>
        <p>
          Please use the tree in the left sidebar to change scope or the fields
          above to adjust filters. Click the button below to proceed.
        </p>
      </Fragment>
    );
  };

  render = () => {
    const {
      hierarchy,
      selectedPath,
      incidentReportFetchState: { inProgress },
      excludeCustomDaysMonths,
      isIncidentReportGenerated
    } = this.props;

    const {
      vertical,
      subcustomer,
      isDateOpen,
      label,
      start,
      end,
      status,
      showTable
    } = this.state;

    const ddVerticals = getUniqueVerticals(hierarchy, selectedPath);
    const ddSubCustomers = getSubCustomers(hierarchy, selectedPath);
    const buttonDisabled = inProgress || isIncidentReportGenerated;

    return (
      <Fragment>
        <Fragment>
          {!isEmpty(selectedPath) && (
            <Fragment>
              <div className="d-flex justify-content-around align-items-center my-3 report-properties">
                <ReportFilterWrapper>
                  <label
                    className="col-form-label-sm pr-2"
                    htmlFor="subcustomer-filter"
                    style={{ whiteSpace: 'pre' }}
                  >
                    Sub-customer:
                  </label>
                  <select
                    className="form-control"
                    id="subcustomer-filter"
                    onChange={this.handleFieldChange('subcustomer')}
                    value={subcustomer}
                  >
                    {ddSubCustomers.map(({ value, text }) => (
                      <option key={`subcustomer-${value}`} value={value}>
                        {text}
                      </option>
                    ))}
                  </select>
                </ReportFilterWrapper>
                <ReportFilterWrapper>
                  <label
                    className="col-form-label-sm pr-2"
                    htmlFor="vertical-filter"
                  >
                    Vertical:
                  </label>
                  <select
                    className="form-control"
                    id="vertical-filter"
                    onChange={this.handleFieldChange('vertical')}
                    value={vertical}
                  >
                    {ddVerticals.map(({ value, text }) => (
                      <option key={`vertical-${value}`} value={value}>
                        {text}
                      </option>
                    ))}
                  </select>
                </ReportFilterWrapper>
                <ReportFilterWrapper>
                  <label className="col-form-label-sm pr-2">Status:</label>
                  <select
                    className="form-control"
                    onChange={this.handleFieldChange('status')}
                    value={status}
                  >
                    {statusOptions.map(({ value, label }) => (
                      <option key={`status-${value}`} value={value}>
                        {label}
                      </option>
                    ))}
                  </select>
                </ReportFilterWrapper>
                <ReportFilterWrapper>
                  <label className="col-form-label-sm pr-2">Date Range:</label>
                  <DateRangeFilter
                    dateRangeFilter={{
                      label,
                      start,
                      end
                    }}
                    isDropDownOpen={isDateOpen}
                    updateDateRangeFilter={this.handleDateSelection}
                    updateIsOpen={this.toggleDate}
                    excludeCustomDaysMonths={excludeCustomDaysMonths}
                    buttonStyle={{
                      backgroundColor: styleLibrary.containerBg,
                      border: '1px solid #ced4da'
                    }}
                  />
                </ReportFilterWrapper>
              </div>
              <div className="text-center">
                {this.getIncidentReportDescription()}
                <p>
                  <button
                    className="btn btn-primary"
                    disabled={buttonDisabled}
                    onClick={this.generateReport}
                  >
                    {inProgress ? (
                      <span>
                        <FaIcon icon="circle-notch" spin classes={['mr-2']} />
                        Generating...
                      </span>
                    ) : (
                      'Generate Incident Report'
                    )}
                  </button>
                </p>
              </div>
            </Fragment>
          )}
        </Fragment>
        {buttonDisabled || showTable ? <IncidentReportTable /> : <div />}
      </Fragment>
    );
  };
}

GenerateIncidentReport.propTypes = {
  isIncidentReportGenerated: PropTypes.bool,
  excludeCustomDaysMonths: PropTypes.bool,
  parsedData: PropTypes.array,
  incidentReportData: PropTypes.object,
  incidentReportFetchState: PropTypes.object,
  hierarchy: PropTypes.object,
  selectedPath: PropTypes.object,
  vertSSIDFilter: PropTypes.object,
  handleGenerateReport: PropTypes.func,
  updateTableReportStatus: PropTypes.func
};

export default GenerateIncidentReport;
