/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { defaultDateFormat } from 'app/constants';
import { rootNodeSelector } from 'app/redux/hierarchy';
import { ajaxGet } from 'app/redux/util-sagas';
import { createErrorAction } from 'app/redux/utils';
import { get } from 'lodash';
import moment from 'moment';
import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';
import {
  fetchClientReportComplete,
  fetchClientReportError,
  reportError,
  updateClientReportFetchState
} from './';

const clientReportUrl = '/api/report/getClientReportDetails';

export function* fetchClientReportSaga({ payload }) {
  yield put(updateClientReportFetchState({ inProgress: true, failed: false }));

  const rootNode = yield select(rootNodeSelector);

  const { vertical, subcustomer, ...otherParams } = yield payload;

  const params = yield {
    ...otherParams,
    vertical,
    subcustomer,
    timeUnit: '24Hours',
    timeUnitFrom: moment()
      .subtract(1, 'days')
      .format(defaultDateFormat),
    timeUnitTo: moment().format(defaultDateFormat),
    wlanName: 'all'
  };

  const queryString = yield Object.entries(params)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');

  const saveFileAs = yield getContext('saveFileAs');
  const filename = yield (rootNode.name || 'Property').toUpperCase() +
    '_' +
    subcustomer.toUpperCase() +
    '_' +
    vertical.toUpperCase() +
    '_DeviceReport_' +
    moment().format('YYYYMMDD_HHmm') +
    '.csv';

  const ajaxOptions = yield {
    headers: { Accept: 'text/csv' },
    responseType: 'blob',
    onTimeout: () => put(updateClientReportFetchState({ failed: true }))
  };

  try {
    const { data } = yield ajaxGet(
      `${clientReportUrl}?${queryString}`,
      ajaxOptions
    );
    if (!(data instanceof Blob)) {
      throw yield new Error('No data');
    }

    if (yield get(payload, 'download', false)) {
      yield call(saveFileAs, data, filename);
    }
    yield put(fetchClientReportComplete(data));
    yield put(updateClientReportFetchState({ failed: false }));
  } catch (err) {
    yield put(createErrorAction(fetchClientReportError, err));
    yield put(updateClientReportFetchState({ failed: true }));
    yield put(
      reportError({
        action: 'generate',
        type: 'client-report',
        report: { ...params },
        error: err
      })
    );
  } finally {
    yield put(updateClientReportFetchState({ inProgress: false }));
  }
}

export default function createClientReportSagas(types) {
  return [takeLatest([types.fetchClientReport], fetchClientReportSaga)];
}
