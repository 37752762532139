/*
 * Copyright 2018-2024 CommScope, Inc., All rights reserved.
 *
 * This program is confidential and proprietary to CommScope, Inc. (CommScope), and
 * may not be copied, reproduced, modified, disclosed to others, published or used, in
 * whole or in part, without the express prior written permission of CommScope.
 */

import { defaultDateFormat } from 'app/constants';
import { ajaxPost } from 'app/redux/util-sagas';
import { createErrorAction } from 'app/redux/utils';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import Papa from 'papaparse';
import { call, getContext, put, takeLatest } from 'redux-saga/effects';
import {
  fetchSwitchPortReportComplete,
  fetchSwitchPortReportError,
  reportError,
  updateSwitchPortReportFetchState
} from '.';

const url = '/api/report/getSwitchDetailsReport';

export function* fetchSwitchPortReportSaga({ payload }) {
  yield put(
    updateSwitchPortReportFetchState({ inProgress: true, failed: false })
  );

  const {
    property = {},
    vertical,
    subcustomer,
    ...otherParams
  } = yield payload;
  const complexApiBody = yield JSON.parse(
    get(property, 'complexApiBody', '{}')
  );

  const finalBody = yield {
    ...complexApiBody,
    vertical,
    subcustomer,
    timeUnit: 'month',
    timeUnitFrom: moment()
      .startOf('month')
      .format(defaultDateFormat),
    timeUnitTo: moment().format(defaultDateFormat),
    ...otherParams
  };

  const finalOptions = yield {
    headers: { Accept: 'text/csv' },
    responseType: 'blob',
    onTimeout: () => put(updateSwitchPortReportFetchState({ failed: true }))
  };

  const queryString = `?vertical=${encodeURIComponent(
    vertical
  )}&subcustomer=${encodeURIComponent(subcustomer)}`;

  const finalUrl = `${url}${queryString}`;

  const saveFileAs = yield getContext('saveFileAs');

  const filename = yield get(property, 'name', 'Property').toUpperCase() +
    '_' +
    vertical.toUpperCase() +
    '_' +
    subcustomer.toUpperCase() +
    '_SwitchReport_' +
    moment().format('YYYYMMDD_HHmm') +
    '.csv';

  try {
    const { data } = yield ajaxPost(finalUrl, finalBody, finalOptions);
    if (!(data instanceof Blob)) {
      throw yield new Error('No data');
    }

    // *** TODO - THIS IS A DANGEROUS CODE - PLEASE REMOVE - START ***
    const textData = yield data.text();
    const parsedData = yield Papa.parse(textData, { header: true }).data;
    const transformedData = yield transformDataForCSV(parsedData);
    const csvContent = yield Papa.unparse(transformedData, { header: true });
    const csvBlob = yield new Blob([csvContent], {
      type: 'text/plain;charset=utf-8'
    });
    // *** TODO - THIS IS A DANGEROUS CODE - PLEASE REMOVE - END ***
    if (yield get(payload, 'download', false)) {
      yield call(saveFileAs, csvBlob, filename);
    }
    yield put(fetchSwitchPortReportComplete(data));
    yield put(updateSwitchPortReportFetchState({ failed: false }));
  } catch (err) {
    yield put(createErrorAction(fetchSwitchPortReportError, err));
    yield put(updateSwitchPortReportFetchState({ failed: true }));
    yield put(
      reportError({
        action: 'generate',
        type: 'switch-port-report',
        report: { ...finalBody },
        error: err
      })
    );
  } finally {
    yield put(updateSwitchPortReportFetchState({ inProgress: false }));
  }
}

const transformDataForCSV = data =>
  data.map(item => ({
    ...item,
    port: !isEmpty(item.port) ? `'${item.port}'` : ''
  }));

export default function createSwitchPortReportSagas(types) {
  return [takeLatest([types.fetchSwitchPortReport], fetchSwitchPortReportSaga)];
}
