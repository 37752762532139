import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  customMuiTableTheme,
  customTableOptions,
  sfdcSummaryTableColumns
} from './utils';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { scrollToBottom } from 'app/utils';
import classNames from 'classnames';
import { Parser } from 'json2csv';
import {
  BlockContainer,
  NoDataToShow,
  TitleBar
} from 'app/components/elements';
import { isSpecificNodeType } from 'app/components/layout/components/sidebar/utils';
import {
  ExportCsvIcon,
  FaIcon,
  LoadingIconPlaceholder,
  StyledExportButton
} from 'app/components/icons';
import { StatefulTable } from 'app/components/tables';
import { FailedFetchStateHandler } from 'app/components/utility';
import {
  dateRangeFilterLabelSelector,
  vertSSIDFilterSelector
} from 'app/redux/filters';
import { selectedPathSelector } from 'app/redux/hierarchy';
import { fetchStatePropTypes } from 'app/redux/utils';
import { findIndex, isArray, isEmpty, isEqual } from 'lodash';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import {
  sfdcCaseDetailsFetchStateSelector,
  sfdcSummarySelector,
  fetchSfdcCaseDetails
} from 'app/redux/incidents';
import CaseAactivityDetails  from './sfdc-activity-table';

class SfdcSummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visibleColumns: this.getDefaultVisibleColumns(),
      dynamicColumns: this.getDynamicColumns(),
      searchText: '',
      caseNumber: '',
      caseid: ''
    };
  }

  componentDidMount = () => {
    // Setting flag to indicate component is mounted
    this._isMounted = true;
    this.getDynamicColumns();
  };

  componentWillUnmount = () => {
    // Unsetting flag when component is unmounted
    this._isMounted = false;
  };

  componentDidUpdate = prevProps => {
    const { dateRangeFilterLabel, selectedPath, vertSSIDFilter } = this.props;
    const {
      dateRangeFilterLabel: prevDateRange,
      selectedPath: prevSelectedPath,
      vertSSIDFilter: prevVertSSIDFilter
    } = prevProps;

    const pathChanged =
      prevSelectedPath && prevSelectedPath.id !== selectedPath.id;
    const dateRangeChanged = prevDateRange !== dateRangeFilterLabel;
    const vertSSIDFilterChanged = !isEqual(prevVertSSIDFilter, vertSSIDFilter);

    if (pathChanged || dateRangeChanged || vertSSIDFilterChanged) {
      this.setState({ caseNumber: '', caseid: '' }, () => {
        this.getDynamicColumns();
      });
    }
  };

  handleCSVExport = () => {
    const { sfdcIncidentSummary = [] } = this.props;
    const { dynamicColumns } = this.state;

    const wrapText = text => {
      if (text === null || text === undefined) {
        return '';
      }
      const normalizedText = text
        .trim()
        .replace(/"/g, '""')
        .replace(/\n+/g, ' ', '\n');

      return `${normalizedText}`;
    };

    const customHeader = dynamicColumns.map(c => ({
      label: c.label,
      value: row => {
        if (c.name === 'curSumNextAction' || c.name === 'description') {
          return wrapText(row[c.name]);
        } else {
          return wrapText(row[c.name]);
        }
      }
    }));
    const parser = new Parser({ fields: customHeader });
    const csv = parser.parse(sfdcIncidentSummary);
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'Incidents.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  handleTableRowClick = (rowData, columnIndex) => {
    const newSelectedCase = rowData[columnIndex];
    const incidentIdColumnIndex = findIndex(sfdcSummaryTableColumns, [
      'name',
      'id'
    ]);
    const caseid = rowData[incidentIdColumnIndex];
    if (newSelectedCase === this.state.caseNumber) {
      this.setState({ caseNumber: '', caseid: '' });
    } else {
      this.setState({ caseNumber: newSelectedCase, caseid }, scrollToBottom);
    }
  };

  setRowProps = (row, columnIndex) => {
    const { caseNumber } = this.state;
    const newSelectedCase = row[columnIndex];
    return {
      className:
        caseNumber !== '' &&
        classNames(
          newSelectedCase === caseNumber ? 'row-selected' : 'row-unselected'
        )
    };
  };

  getDynamicColumns = () => {
    const { selectedPath } = this.props;
    const isPropertyLevel = isSpecificNodeType(selectedPath, 'zonename');
    const columns = sfdcSummaryTableColumns.map(column => {
      if (
        column.name === 'createddatetime' ||
        column.name === 'issuestart' ||
        column.name === 'escalatedon' ||
        column.name === 'issueresolved' ||
        column.name === 'datatimelastmodified' ||
        column.name === 'recoverydatetime' ||
        column.name === 'resolutiondate'
      ) {
        return {
          ...column,
          label: isPropertyLevel ? column.label : `${column.label} (UTC)`
        };
      }
      return column;
    });
    if (this._isMounted) {
      this.setState({ dynamicColumns: columns });
    }
  };

  getDefaultVisibleColumns = () => {
    return sfdcSummaryTableColumns
      .filter(column => !column.options || column.options.display !== false)
      .map(column => column.name);
  };

  handleColumnViewChange = (changedColumn, action) => {
    this.setState(prevState => {
      let updatedVisibleColumns = [...prevState.visibleColumns];
      if (action === 'add') {
        updatedVisibleColumns.push(changedColumn);
      } else {
        updatedVisibleColumns = updatedVisibleColumns.filter(
          column => column !== changedColumn
        );
      }
      return { visibleColumns: updatedVisibleColumns };
    });
  };

  handleSearchChange = searchText => {
    this.setState({ searchText });
  };

  renderSearchBox = (searchText, handleSearch) => (
    <Fragment>
      <FaIcon icon="search" classes={['mr-2']} />
      <label className="sr-only" htmlFor="sfdc-search">
        Search
      </label>
      <input
        className="p-1"
        id="sfdc-search"
        onChange={e => {
          handleSearch(e.target.value);
          this.handleSearchChange(e.target.value);
        }}
        placeholder="Search..."
        type="text"
        value={this.state.searchValue}
      />
    </Fragment>
  );

  toggleActivityTable = () => this.setState({ caseNumber: '', caseid: '' });

  render = () => {
    const {
      fetchSfdcCaseDetails,
      sfdcIncidentSummary = [],
      fetchState
    } = this.props;
    const {
      visibleColumns,
      searchText,
      dynamicColumns,
      caseNumber,
      caseid
    } = this.state;

    const { pending, complete, failed } = fetchState;
    const hasData =
      isArray(sfdcIncidentSummary) && !isEmpty(sfdcIncidentSummary);
    const noDataToShow = !pending && complete && !hasData;

    const columns = dynamicColumns
      ? dynamicColumns.map(column => ({
        ...column,
        options: {
          ...(column.options || {}),
          display: visibleColumns.includes(column.name)
        }
      }))
      : [];

    const incidentSummaryOptions = {
      ...customTableOptions,
      customSearchRender: (searchText, handleSearch) =>
        this.renderSearchBox(searchText, handleSearch),
      onRowClick: rowData => this.handleTableRowClick(rowData, columnIndex),
      setRowProps: row => this.setRowProps(row, columnIndex),
      onColumnViewChange: this.handleColumnViewChange,
      fixedSelectColumn: {
        columnIndex: 0,
        align: 'right'
      },
      columnView: visibleColumns,
      searchText: searchText
    };

    const columnIndex = findIndex(sfdcSummaryTableColumns, [
      'name',
      'casenumber'
    ]);

    return (
      <Fragment>
        <BlockContainer classes={['mb-4', 'h-auto']}>
          <TitleBar
            leftChildren="Incidents Summary"
            rightChildren={
              complete &&
              hasData && (
                <StyledExportButton
                  title="Generate CSV"
                  onClick={this.handleCSVExport}
                >
                  <ExportCsvIcon />
                </StyledExportButton>
              )
            }
          />
          <FailedFetchStateHandler
            fetchState={fetchState}
            retry={fetchSfdcCaseDetails}
          >
            {pending ? (
              <LoadingIconPlaceholder position="inherit" />
            ) : noDataToShow || failed ? (
              <NoDataToShow
                message={
                  failed
                    ? 'There has been a problem fetching the incidents summary'
                    : 'No incidents available'
                }
                style={{ background: 'none', position: 'relative' }}
              />
            ) : (
              complete &&
              hasData && (
                <MuiThemeProvider theme={createMuiTheme(customMuiTableTheme)}>
                  <StatefulTable
                    tableKey="incident-summary"
                    data={sfdcIncidentSummary}
                    columns={columns}
                    options={incidentSummaryOptions}
                  />
                </MuiThemeProvider>
              )
            )}
          </FailedFetchStateHandler>
        </BlockContainer>
        {caseNumber !== '' && (
          <CaseAactivityDetails
            caseNumber={caseNumber}
            caseid={caseid}
            toggleActivityTable={this.toggleActivityTable}
          />
        )}
      </Fragment>
    );
  };
}

SfdcSummary.propTypes = {
  dateRangeFilterLabel: PropTypes.string,
  selectedPath: PropTypes.object,
  vertSSIDFilter: PropTypes.object,
  fetchSfdcCaseDetails: PropTypes.func,
  sfdcIncidentSummary: PropTypes.array,
  fetchState: fetchStatePropTypes
};

const mapStateToProps = createSelector(
  vertSSIDFilterSelector,
  dateRangeFilterLabelSelector,
  selectedPathSelector,
  sfdcSummarySelector,
  sfdcCaseDetailsFetchStateSelector,
  (
    vertSSIDFilter,
    dateRangeFilterLabel,
    selectedPath,
    sfdcIncidentSummary,
    fetchState
  ) => ({
    vertSSIDFilter,
    dateRangeFilterLabel,
    selectedPath,
    sfdcIncidentSummary,
    fetchState
  })
);

const mapDispatchToProps = { fetchSfdcCaseDetails };

export default connect(mapStateToProps, mapDispatchToProps)(SfdcSummary);
